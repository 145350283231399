import { createReducer, on } from "@ngrx/store";
import * as PerfilActions from "../actions/perfil.actions";
import { PerfilState } from "../interfaces/perfil-state.interface";

export const initialState: PerfilState = {
  perfis: [],
  perfisByNode: {},
  perfisByUsuarioRepresentanteId: {},
  error: null,
  isLoaded: {
    loadPerfisByUsuarioRepresentante: true,
    loadPerfisBySistema: true,
    loadNodeProfiles: true,
    loadPerfisMapByUsuarioRepresentante: true,
    loadColaboradorProfilesByNode: true,
  },
};

export const perfilReducer = createReducer(
  initialState,
  on(PerfilActions.loadPerfisByUsuarioRepresentante, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadPerfisByUsuarioRepresentante: false },
    error: null,
  })),
  on(
    PerfilActions.loadPerfisByUsuarioRepresentanteSuccess,
    (state, { perfisCustom }) => ({
      ...state,
      perfisCustom,
      isLoaded: { ...state.isLoaded, loadPerfisByUsuarioRepresentante: true },
    })
  ),
  on(
    PerfilActions.loadPerfisByUsuarioRepresentanteFailure,
    (state, { error }) => ({
      ...state,
      error,
      isLoaded: { ...state.isLoaded, loadPerfisByUsuarioRepresentante: true },
    })
  ),
  on(PerfilActions.loadPerfisBySistema, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadPerfisBySistema: false },
    error: null,
  })),
  on(PerfilActions.loadPerfisBySistemaSuccess, (state, { perfis }) => ({
    ...state,
    perfis,
    isLoaded: { ...state.isLoaded, loadPerfisBySistema: true },
  })),
  on(PerfilActions.loadPerfisBySistemaFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, loadPerfisBySistema: true },
  })),

  on(PerfilActions.loadNodeProfiles, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadNodeProfiles: false },
    error: null,
  })),
  on(PerfilActions.loadNodeProfilesSuccess, (state, { sistemaId, perfis }) => ({
    ...state,
    perfisByNode: { ...state.perfisByNode, [sistemaId]: perfis },
    isLoaded: { ...state.isLoaded, loadNodeProfiles: true },
  })),
  on(PerfilActions.loadNodeProfilesFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, loadNodeProfiles: true },
  })),

  on(PerfilActions.loadColaboradorProfilesByNode, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadColaboradorProfilesByNode: false },
    error: null,
  })),

  on(
    PerfilActions.loadColaboradorProfilesByNodeSuccess,
    (state, { idUsuarioRepresentante, sistemaId, perfis }) => {
      const updatedProfilesByUser = {
        ...state.perfisByUsuarioRepresentanteId,
        [idUsuarioRepresentante]: {
          ...(state.perfisByUsuarioRepresentanteId[idUsuarioRepresentante] ||
            {}),
          [sistemaId]: perfis,
        },
      };

      return {
        ...state,
        perfisByUsuarioRepresentanteId: updatedProfilesByUser,
        isLoaded: { ...state.isLoaded, loadColaboradorProfilesByNode: true },
      };
    }
  ),
  on(
    PerfilActions.loadColaboradorProfilesByNodeFailure,
    (state, { error }) => ({
      ...state,
      error,
      isLoaded: { ...state.isLoaded, loadColaboradorProfilesByNode: true },
    })
  ),

  on(PerfilActions.clearPerfilState, () => ({
    ...initialState,
    isLoaded: {
      loadPerfisByUsuarioRepresentante: true,
      loadPerfisBySistema: true,
      loadNodeProfiles: true,
      loadColaboradorProfilesByNode: true,
      loadPerfisMapByUsuarioRepresentante: true,
    },
  })),
  on(PerfilActions.clearNodeProfiles, (state) => ({
    ...state,
    perfisByNode: {},
  })),

  on(PerfilActions.clearColaboradorProfilesNode, (state) => ({
    ...state,
    perfisByUsuarioRepresentanteId: {},
  })),

  on(
    PerfilActions.clearColaboradorProfilesNodeByUsuarioRepresentanteId,
    (state, { idUsuarioRepresentante }) => ({
      ...state,
      perfisByUsuarioRepresentanteId: {
        ...state.perfisByUsuarioRepresentanteId,
        [idUsuarioRepresentante]: {},
      },
    })
  )
);
