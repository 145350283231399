import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, ObservableInput, map } from 'rxjs';
import { ColaboradorSerpro } from '../model/colaboradorserpro';
import { PessoaJuridicaSerpro } from '../model/pessoajuridicaserpro';

@Injectable({
  providedIn: 'root'
})
export class SerproService {

  private readonly API = `${environment.API}/api/v1/bcadastros`;
  error: ((err: any, caught: Observable<object>) => ObservableInput<any>) | undefined;
  
  constructor(
    private httpClient: HttpClient) {}
  
  verificarValidadeCPF(cpf: string) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    const requestOptions = { headers: headers};
    return this.httpClient.get<ColaboradorSerpro>(this.API + '/verificar-cpf/'+cpf, requestOptions).pipe(map((res) => res));
  }

  consultaCNPJ(cnpj: string) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    const requestOptions = { headers: headers};
    return this.httpClient.get<PessoaJuridicaSerpro>(this.API + '/consultas-empresas/'+cnpj, requestOptions).pipe(map((res) => res));
  }

}
