import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import {
  Observable,
  Subject,
  Subscription,
  catchError,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from "rxjs";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";

import { Functions } from "src/app/util/functions";
import { Sistema } from "src/app/model/sistema.model";
import { Perfil } from "src/app/model/perfil.model";
import {
  selectAllSistemas,
  selectAllSistemasByUser,
} from "src/app/selectors/sistema.selector";
import {
  selectAllPerfis,
  selectProfilesByNodeId,
} from "src/app/selectors/perfil.selector";
import {
  clearPerfilState,
  loadNodeProfiles,
  loadPerfisBySistema,
  loadPerfisByUsuarioRepresentante,
} from "src/app/actions/perfil.actions";
import {
  clearSistemaState,
  loadSistemaByUser,
  loadSistemas,
} from "src/app/actions/sistema.actions";
import { DynamicFlatNode } from "src/app/model/dynamicflatnode";
import { DynamicNode } from "src/app/model/dynamicnode";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { ToastrFunctions } from "src/app/util/toastr.functions";
import { PessoaJuridica } from "src/app/model/pessoajuridica.model";
import { Constants } from "src/app/util/constants";
import { ColaboradorCustom } from "src/app/model/colaboradorcustom";
import {
  selectEmployee,
  selectIsUpdatedEmployee,
} from "src/app/selectors/employee.selector";
import {
  deletePermissions,
  editEmployee,
  insertSystemProfiles,
  setUserBlockStatus,
  toggleUserBlockStatus,
  updateExpirationDate,
} from "src/app/actions/employee.action";
import {
  selectAllCompanies,
  selectCompany,
} from "src/app/selectors/company.selector";
import { AppState } from "src/app/interfaces/app-state.interface";
import { selectProfile } from "src/app/selectors/auth.selectors";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
  selector: "app-dialog-colab-atr-perfil",
  templateUrl: "./dialog-colab-atr-perfil.component.html",
  styleUrls: ["./dialog-colab-atr-perfil.component.scss"],
})
export class DialogColabAtrPerfilComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  idUsuarioRepresentante!: string;
  noPessoaFisica!: string;
  cnpj!: string;
  empresa!: string;
  cnes!: string;

  cpf!: string;
  isGestorCadastroSelected = false;
  pessoaJuridicaId!: string;
  dtExpiracao!: Date | null;

  isPerfilGestorAnvisa = false;
  isPerfilGestorCadastrosResponsavelLegal = false;

  transformer = (node: DynamicNode, level: number): DynamicFlatNode => {
    return new DynamicFlatNode(
      node.id,
      node.item,
      level,
      node.hasChildren,
      node.isLoading
    );
  };

  hasChild = (_: number, node: DynamicFlatNode) => node.expandable;

  treeControl = new FlatTreeControl<DynamicFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener = new MatTreeFlattener<DynamicNode, DynamicFlatNode>(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  sistemas$: Observable<Sistema[]>;
  sistemasByUser$: Observable<Sistema[]>;
  perfis$: Observable<Perfil[]>;
  selectedUser$: Observable<ColaboradorCustom | undefined>;
  allCompanies$!: Observable<PessoaJuridica[]>;
  isUpdated$!: Observable<boolean>;
  profileSelected$!: Observable<string | null>;
  selectedCompany$: Observable<PessoaJuridica | undefined>;

  selectedSistemaId = "";
  selectedPerfilId = "";

  private dataSubscription?: Subscription;

  cnpjs: PessoaJuridica[] = [];
  pessoasjuridicas: PessoaJuridica[] = [];
  // Exposing Constants to the template
  Constants = Constants;

  constructor(
    public activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>
  ) {
    this.sistemas$ = this.store.pipe(select(selectAllSistemas));
    this.sistemasByUser$ = this.store.pipe(select(selectAllSistemasByUser));
    this.perfis$ = this.store.pipe(select(selectAllPerfis));
    this.selectedUser$ = this.store.select(selectEmployee);
    this.allCompanies$ = this.store.pipe(select(selectAllCompanies));
    this.isUpdated$ = this.store.select(selectIsUpdatedEmployee);
    this.profileSelected$ = this.store.select(selectProfile);
    this.selectedCompany$ = this.store.pipe(select(selectCompany));
  }

  ngOnInit() {
    this.selectedUser$.subscribe((user) => {
      if (user?.dtExpiracao) {
        this.dtExpiracao = new Date(user.dtExpiracao);
      } else {
        this.dtExpiracao = null;
      }
    });

    this.setUserDataFromSession();
    this.loadInitialData();
  }

  setUserDataFromSession() {
    const usuarioLogado = Functions.getUsuarioLogadoFromSession();
    this.cpf = usuarioLogado.preferredUsername;
    this.idUsuarioRepresentante = this.data.idUsuarioRepresentante;
    this.noPessoaFisica = this.data.noPessoaFisica;
    this.cnpj = this.data.cnpj;
    this.empresa = this.data.empresa;
    this.cnes = this.data.cnes;
    this.pessoaJuridicaId = this.data.pessoaJuridicaId;
    this.isPerfilGestorAnvisa = this.data.isPerfilGestorAnvisa;
    this.isPerfilGestorCadastrosResponsavelLegal =
      this.data.isPerfilGestorCadastrosResponsavelLegal;
  }

  loadInitialData() {
    this.store.dispatch(clearPerfilState());
    this.store.dispatch(clearSistemaState());

    this.store.dispatch(
      editEmployee({
        idPessoaJuridica: this.pessoaJuridicaId,
        idUsuarioRepresentante: this.idUsuarioRepresentante,
      })
    );

    this.store.dispatch(
      loadPerfisByUsuarioRepresentante({
        idUsuarioRepresentante: this.idUsuarioRepresentante,
      })
    );
    this.store.dispatch(
      loadSistemaByUser({
        idUsuarioRepresentante: this.idUsuarioRepresentante,
      })
    );
    this.store.dispatch(loadSistemas());

    this.dataSubscription = this.sistemasByUser$
      .pipe(
        // Debugging or logging can be placed here to track the values.
        switchMap(
          (sistemas) =>
            // Use combineLatest only when systems are available to avoid empty requests.
            sistemas.length > 0
              ? combineLatest(
                  sistemas.map((sistema) =>
                    this.createChildren(sistema.id).pipe(
                      map(
                        (children) =>
                          new DynamicNode(
                            sistema.id,
                            sistema.sgSistema,
                            true,
                            false,
                            children
                          )
                      )
                    )
                  )
                )
              : of([]) // Return an empty array when no systems are present to avoid errors.
        ),
        takeUntil(this.destroy$),
        catchError((error) => {
          ToastrFunctions.showError(this.toastr, "Failed to load tree data.");
          return of([]); // Return an empty array to keep the stream alive.
        })
      )
      .subscribe({
        next: (nodesArray) => (this.dataSource.data = nodesArray.flat()),
        error: (err) =>
          console.error("Failed to subscribe to node arrays", err),
      });
  }

  createChildren(id: string): Observable<DynamicNode[]> {
    return this.store.select(selectProfilesByNodeId(id)).pipe(
      take(1),
      tap((profiles) => {
        console.log("Received profiles:", profiles); // Debugging line
        if (!profiles || !profiles.length) {
          this.store.dispatch(
            loadNodeProfiles({
              coSistema: id,
              idUsuarioRepresentante: this.idUsuarioRepresentante,
            })
          );
        }
      }),
      switchMap((profiles) => {
        if (!profiles || !profiles.length) {
          return this.waitForProfiles(id);
        }
        return of(profiles);
      }),
      catchError((error) => {
        ToastrFunctions.showError(
          this.toastr,
          "Failed to load data for the selected node."
        );
        return throwError(
          () => new Error(`Failed to load data for node: ${error}`)
        );
      }),
      map((profiles) =>
        profiles.map(
          (profile) =>
            new DynamicNode(profile.id, profile.noPerfil, false, false)
        )
      ),
      catchError((err) => {
        return of([]); // Return empty array on error to keep the UI functional
      })
    );
  }

  private waitForProfiles(id: string): Observable<Perfil[]> {
    return this.store.select(selectProfilesByNodeId(id)).pipe(
      filter((profiles) => !!profiles && profiles.length > 0),
      take(1)
    );
  }

  loadPerfis() {
    if (this.selectedSistemaId !== "") {
      this.store.dispatch(
        loadPerfisBySistema({
          sistemaId: this.selectedSistemaId,
        })
      );
      this.selectedPerfilId = "";
      this.isGestorCadastroSelected = false;
    } else {
      ToastrFunctions.showError(
        this.toastr,
        "Por favor, selecione um sistema para os perfis"
      );
    }
  }

  onDeleteNode(node: DynamicFlatNode) {
    if (!node) return;

    // Collect all IDs that need deletion, including descendants if any
    const idsToDelete = new Set<string>();
    if (node.expandable) {
      const descendants = this.treeControl.getDescendants(node);
      descendants.forEach((descendant) => idsToDelete.add(descendant.id));
    } else {
      idsToDelete.add(node.id);
    }

    if (idsToDelete.size > 0) {
      console.log(idsToDelete);
      this.deletaPermissao(Array.from(idsToDelete));
    }
  }

  deletaPermissao(idsPerfil: string[]): void {
    this.store.dispatch(
      deletePermissions({
        perfisIds: idsPerfil,
      })
    );
  }

  openDialogExclusao(node: DynamicFlatNode): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: { node },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onDeleteNode(node);
      }
    });
  }

  insereSistemaPerfisUsuario() {
    if (this.isGestorCadastroSelected) {
      if (this.cnpjs.length === 0) {
        ToastrFunctions.showWarning(
          this.toastr,
          "Seleciona ao menos uma pessoa jurídica para atribuir o gestor de cadastro."
        );
        return;
      }
    }

    const cnpjIds = this.isGestorCadastroSelected
      ? this.cnpjs.map((cnpj) => cnpj.id as string)
      : [];

    this.store.dispatch(
      insertSystemProfiles({
        idSistema: this.selectedSistemaId,
        idsPerfis: [this.selectedPerfilId],
        cnpjsOrigem: cnpjIds,
        cpf: this.cpf,
      })
    );

    this.selectedPerfilId = "";
    this.selectedSistemaId = "";
    this.cnpjs = [];
    this.isGestorCadastroSelected = false;
  }

  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedOption = selectElement.options[selectElement.selectedIndex];

    // Check if selectedOption is defined before accessing its text property
    const selectedLabel = selectedOption ? selectedOption.text : null;

    if (selectedLabel === Constants.PERFIL_GESTOR_CADASTROS) {
      this.isGestorCadastroSelected = true;
    } else {
      this.isGestorCadastroSelected = false;
    }
    this.cnpjs = [];
  }

  atualizaDataExpiracao() {
    let dataExpiracao = null;

    if (this.dtExpiracao) {
      const expirationDate = this.dtExpiracao;
      // Convert back to ISO string (YYYY-MM-DD format) for dispatching
      dataExpiracao = expirationDate.toISOString().substring(0, 10);
    }

    this.store.dispatch(
      updateExpirationDate({
        dtExpiracao: dataExpiracao,
      })
    );
  }

  ativaInativaColaborador(event: MatSlideToggleChange): void {
    if (event.source.disabled) return;

    const newStatus = event.checked ? "N" : "S";

    // Optimistically update the UI
    this.store.dispatch(setUserBlockStatus({ status: newStatus }));

    // Dispatch the action with the new status
    this.store.dispatch(toggleUserBlockStatus({ status: newStatus }));
  }

  trackByCompanyFn(index: number, company: any): number {
    return company.id; // Assuming 'id' is the unique identifier for each company
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
