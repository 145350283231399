import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Store, select } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, interval } from "rxjs";
import { switchMap, takeUntil, tap, takeWhile } from "rxjs/operators";
import { clearCNES, consultaCNES, retryCNESFinish, retryCNESStart } from "src/app/actions/company.action";
import { AppState } from "src/app/interfaces/app-state.interface";
import { PessoaJuridica } from "src/app/model/pessoajuridica.model";
import { PessoasJuridicas } from "src/app/model/pessoasjuridicas.model";
import { selectCNESCompanies } from "src/app/selectors/company.selector";
import { Functions } from "src/app/util/functions";
import { ToastrFunctions } from "src/app/util/toastr.functions";

@Component({
  selector: "app-dialog-search-instituicao",
  templateUrl: "./dialog-search-instituicao.component.html",
})
export class DialogSearchInstituicaoComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  readonly pageSizes = [20, 50, 100];
  displayedColumns = ["id", "cnpj", "cnes", "razaoSocial"];
  dataSource = new MatTableDataSource<PessoaJuridica>();
  companies$!: Observable<PessoasJuridicas | null>;

  cnes = "";
  totalElements = 0;
  isLoading = false;
  hasInputError = false;
  private noResultsMessageShown = false; // Prevent multiple no-results messages
  private attempts = 0; // Attempts counter reset for each search

  private readonly destroy$ = new Subject<void>();
  private maxRetries = 4;
  private retryInterval = 10000;

  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<DialogSearchInstituicaoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.cnes = this.data.cnes || "";
  }

  ngOnInit(): void {
    this.companies$ = this.store.pipe(select(selectCNESCompanies));
  }

  findInstituicoesByCnes(page = 0, size = 20): void {
    this.resetErrors();
    if (!this.validateCnesInput()) return;

    this.startLoading();
    this.dispatchConsultaCNES(page, size);
    this.retryFetchingData(page, size);
  }

  private validateCnesInput(): boolean {
    const trimmedCnes = this.cnes.trim();
    if (!trimmedCnes) {
      ToastrFunctions.showWarning(this.toastr, "Preencha o CNES.");
      this.hasInputError = true;
      return false;
    }
    if (trimmedCnes.length < 7) {
      ToastrFunctions.showWarning(this.toastr, "O CNES deve ter pelo menos 7 dígitos.");
      this.hasInputError = true;
      return false;
    }
    return true;
  }

  private retryFetchingData(page: number, size: number): void {
    this.attempts = 0;
    this.noResultsMessageShown = false; // Prevent multiple no-results messages

    interval(this.retryInterval)
      .pipe(
        takeWhile(() => this.isLoading && this.attempts < this.maxRetries),
        tap(() => this.attempts++),
        switchMap(() => this.dispatchConsultaCNES(page, size)),
        takeUntil(this.destroy$)
      )
      .subscribe((companies) => {
        if (companies && companies.content && companies.content.length > 0) {
          this.updateDataSource(companies);
          this.finishLoading();
        } else if (this.attempts === this.maxRetries && !this.noResultsMessageShown) {
          this.showNoResultsMessage();
          this.finishLoading();
        }
      });
  }

  private updateDataSource(companies: PessoasJuridicas): void {
    this.dataSource.data = companies.content;
    this.totalElements = companies.totalElements;
  }

  private finishLoading(): void {
    this.isLoading = false;
    this.store.dispatch(retryCNESFinish());
  }

  private startLoading(): void {
    this.isLoading = true;
    this.store.dispatch(retryCNESStart());
  }

  private dispatchConsultaCNES(page: number, size: number): Observable<PessoasJuridicas | null> {
    this.store.dispatch(consultaCNES({ cnes: this.cnes, page, size }));
    return this.companies$;
  }

  private showNoResultsMessage(): void {
    ToastrFunctions.showInfo(
      this.toastr,
      "Nenhum resultado encontrado para a consulta. O CNES informado pode não existir ou estar com status 'Desativado' ou 'Excluído'."
    );
    this.noResultsMessageShown = true; // Set the flag to prevent further messages
  }

  selecionaInstituicao(pessoaJuridica: PessoaJuridica): void {
    this.dialogRef.close(pessoaJuridica);
    this.store.dispatch(clearCNES());
  }

  nextPage(event: PageEvent): void {
    this.findInstituicoesByCnes(event.pageIndex, event.pageSize);
  }

  reset(): void {
    this.cnes = "";
    this.dataSource.data = [];
    this.totalElements = 0;
    this.store.dispatch(clearCNES());
    this.hasInputError = false;
  }

  formatCnpj(cnpj: string): string {
    return Functions.formatCnpj(cnpj);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private resetErrors(): void {
    this.hasInputError = false;
  }
}
