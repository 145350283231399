// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.container {
  display: flex;
  flex-direction: column;
  width: 900px; /* Adjust width as needed */
  margin: 0 auto;
  /*border: 1px solid #ccc;*/
  padding: 10px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.column {
  /*flex: 1;*/
  padding: 10px;
  /*border: 1px solid #ccc;*/
}

.container-vertical-align {
  display: flex;
  align-items: center; /* Vertical alignment */
  height: 77px;
  /*border: 1px solid #ccc;*/
}

.input-button-wrapper {
  display: flex;
  align-items: center;
}

.button-icon {
  margin-right: 8px;
  color: #1976d2; /* Primary color to match the button style */
}`, "",{"version":3,"sources":["webpack://./src/app/colaborador-vinc-cnpj/dialog-colab-atr-perfil-lote/dialog-colab-atr-perfil-lote.component.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,SAAA;EACA,UAAA;EACA,yBAAA;AACJ;;AACE;EACE,aAAA;EACA,sBAAA;EACA,YAAA,EAAA,2BAAA;EACA,cAAA;EACA,0BAAA;EACA,aAAA;AAEJ;;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAGJ;;AADE;EACE,WAAA;EACA,aAAA;EACA,0BAAA;AAIJ;;AADE;EACE,aAAA;EACA,mBAAA,EAAA,uBAAA;EACA,YAAA;EACA,0BAAA;AAIJ;;AADE;EACE,aAAA;EACA,mBAAA;AAIJ;;AADE;EACE,iBAAA;EACA,cAAA,EAAA,4CAAA;AAIJ","sourcesContent":["body {\n    font-family: Arial, sans-serif;\n    margin: 0;\n    padding: 0;\n    background-color: #f0f0f0;\n  }\n  .container {\n    display: flex;\n    flex-direction: column;\n    width: 900px; /* Adjust width as needed */\n    margin: 0 auto;\n    /*border: 1px solid #ccc;*/\n    padding: 10px;\n  }\n  .row {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 10px;\n  }\n  .column {\n    /*flex: 1;*/\n    padding: 10px;\n    /*border: 1px solid #ccc;*/\n  }\n  \n  .container-vertical-align {\n    display: flex;\n    align-items: center; /* Vertical alignment */\n    height: 77px;\n    /*border: 1px solid #ccc;*/\n  }\n\n  .input-button-wrapper {\n    display: flex;\n    align-items: center;\n  }\n  \n  .button-icon {\n    margin-right: 8px;\n    color: #1976d2; /* Primary color to match the button style */\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
