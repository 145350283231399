// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dialog Title */
.dialog-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

/* Main Content */
.main-content {
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 8px;
}

/* Section Styling */
.section-title {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.section-content {
  margin: 0;
  font-size: 1rem;
  margin-bottom: 1rem;
}

/* Perfis List */
.perfis-list {
  margin: 0;
  padding-left: 1.5rem;
  font-size: 1rem;
}

/* Dialog Actions */
.dialog-actions {
  margin-top: 1.5rem;
  display: flex; /* Enables flexbox layout */
  justify-content: center; /* Horizontally centers child elements */
}

.dialog-button {
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  border-radius: 15px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/gestao-perfil-colab-inter/dialog-add-perfil-colab/dialog-add-perfil-colab.component.scss"],"names":[],"mappings":"AAAA,iBAAA;AACA;EACE,kBAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA,iBAAA;AACA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA,oBAAA;AACA;EACE,SAAA;EACA,iBAAA;EACA,iBAAA;AACF;;AAEA;EACE,SAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA,gBAAA;AACA;EACE,SAAA;EACA,oBAAA;EACA,eAAA;AACF;;AAEA,mBAAA;AACA;EACE,kBAAA;EACA,aAAA,EAAA,2BAAA;EACA,uBAAA,EAAA,wCAAA;AACF;;AAEA;EACE,sBAAA;EACA,eAAA;EACA,mBAAA;EACA,eAAA;AACF","sourcesContent":["/* Dialog Title */\n.dialog-title {\n  text-align: center;\n  font-size: 1.5rem;\n  margin-bottom: 1rem;\n}\n\n/* Main Content */\n.main-content {\n  padding: 1rem;\n  background: #f9f9f9;\n  border-radius: 8px;\n}\n\n/* Section Styling */\n.section-title {\n  margin: 0;\n  font-size: 1.2rem;\n  font-weight: bold;\n}\n\n.section-content {\n  margin: 0;\n  font-size: 1rem;\n  margin-bottom: 1rem;\n}\n\n/* Perfis List */\n.perfis-list {\n  margin: 0;\n  padding-left: 1.5rem;\n  font-size: 1rem;\n}\n\n/* Dialog Actions */\n.dialog-actions {\n  margin-top: 1.5rem;\n  display: flex; /* Enables flexbox layout */\n  justify-content: center; /* Horizontally centers child elements */\n}\n\n.dialog-button {\n  padding: 0.5rem 1.5rem;\n  font-size: 1rem;\n  border-radius: 15px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
