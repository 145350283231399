import { createReducer, on } from "@ngrx/store";
import * as EmployeeActions from "../actions/employee.action";
import { EmployeeState } from "../interfaces/employee-state.interface";

export const initialState: EmployeeState = {
  selectedEmployee: undefined,
  selectedEmployees: [],
  filteredEmployees: [],
  updated: false,
  employees: [],
  error: undefined,
  filtered: false,
  colaboradorSerpro: undefined,
  isLoaded: {
    loadAllEmployees: true,
    editEmployee: true,
    updateExpirationDate: true,
    toggleUserBlockStatus: true,
    insertSystemProfiles: true,
    deletePermissions: true,
    editEmployees: true,
    deletePermissionsByUsuarioRepresentanteId: true,
    insertSystemProfilesBatch: true,
    insereColaborador: true,
    insereColaboradorInterno: true,
    verificarValidadeCPF: true
  },
};

export const employeeReducer = createReducer(
  initialState,
  on(EmployeeActions.chooseEmployees, (state) => ({
    ...state,
    error: null, // Clear any previous error
  })),
  on(
    EmployeeActions.chooseEmployeesSuccess,
    (state, { filteredEmployees }) => ({
      ...state,
      filteredEmployees,
      filtered: true, // Set filtered flag to true
    })
  ),
  on(EmployeeActions.chooseEmployeesFailure, (state, { error }) => ({
    ...state,
    error,
    filtered: false, // Set filtered flag to false
  })),
  on(EmployeeActions.loadAllEmployees, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadAllEmployees: false },
  })),
  
  on(EmployeeActions.loadAllEmployeesSuccess, (state, { employees }) => {
    // Always update the employees array with the new data
    const updatedEmployees = employees;
  
    // Update selectedEmployee based on the new employees list
    const updatedSelectedEmployee = updatedEmployees.find(emp => emp.idUsuarioRepresentante === state.selectedEmployee?.idUsuarioRepresentante);
  
    // Update selectedEmployees based on the new employees list
    const updatedSelectedEmployees = state.selectedEmployees.map(selectedEmp => 
      updatedEmployees.find(emp => emp.idUsuarioRepresentante === selectedEmp.idUsuarioRepresentante) || selectedEmp
    );
  
    // Conditionally update filteredEmployees if filtering is enabled
    const updatedFilteredEmployees = state.filtered
      ? state.filteredEmployees.map(filteredEmp =>
          updatedEmployees.find(emp => emp.idUsuarioRepresentante === filteredEmp.idUsuarioRepresentante) || filteredEmp
        )
      : state.filteredEmployees;
  
    return {
      ...state,
      employees: updatedEmployees, // always updated with the new employees
      selectedEmployee: updatedSelectedEmployee || state.selectedEmployee, // updated based on the new employees
      selectedEmployees: updatedSelectedEmployees, // updated based on the new employees
      filteredEmployees: updatedFilteredEmployees, // conditionally updated based on the new employees
      isLoaded: { ...state.isLoaded, loadAllEmployees: true },
    };
  }),
  
  on(EmployeeActions.loadAllEmployeesFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, loadAllEmployees: true },
  })),

  on(EmployeeActions.editEmployee, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, editEmployee: false },
    updated: false,
  })),
  on(EmployeeActions.editEmployeeSuccess, (state, { employee }) => ({
    ...state,
    selectedEmployee: employee, // Assuming you mean to update selectedEmployee
    isLoaded: { ...state.isLoaded, editEmployee: true },
  })),
  on(EmployeeActions.editEmployeeFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, editEmployee: true },
  })),

  on(EmployeeActions.editInternalEmployee, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, editInternalEmployee: false },
    updated: false,
  })),
  on(EmployeeActions.editInternalEmployeeSuccess, (state, { employee }) => ({
    ...state,
    selectedInternalEmployee: employee, // Assuming you mean to update selectedInternalEmployee
    isLoaded: { ...state.isLoaded, editInternalEmployee: true },
  })),
  on(EmployeeActions.editInternalEmployeeFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, editInternalEmployee: true },
  })),

  on(EmployeeActions.updateExpirationDate, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, updateExpirationDate: false },
  })),
  on(EmployeeActions.updateExpirationDateSuccess, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, updateExpirationDate: true },
    updated: true,
  })),
  on(EmployeeActions.updateExpirationDateFailure, (state, { error }) => ({
    ...state,
    isLoaded: { ...state.isLoaded, updateExpirationDate: true },
    error,
  })),

  // Set `isLoaded` to false on toggle attempt
  on(EmployeeActions.toggleUserBlockStatus, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, toggleUserBlockStatus: false },
  })),
  // Confirm update on success and reset `isLoaded`
  on(EmployeeActions.toggleUserBlockStatusSuccess, (state, { isUpdated }) => ({
    ...state,
    isLoaded: { ...state.isLoaded, toggleUserBlockStatus: true },
    updated: isUpdated,
    // Optionally confirm status update in `selectedEmployee`
    selectedEmployee: state.selectedEmployee && isUpdated
      ? { ...state.selectedEmployee, stBloqueado: state.selectedEmployee.stBloqueado }
      : state.selectedEmployee,
  })),
  // Handle failure and reset `isLoaded` without modifying `stBloqueado`
  on(EmployeeActions.toggleUserBlockStatusFailure, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, toggleUserBlockStatus: true },
  })),
  // Optimistic UI update for immediate feedback
  on(EmployeeActions.setUserBlockStatus, (state, { status }) => ({
    ...state,
    selectedEmployee: state.selectedEmployee
      ? { ...state.selectedEmployee, stBloqueado: status }
      : state.selectedEmployee,
  })),
  on(EmployeeActions.insertSystemProfiles, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, insertSystemProfiles: false },
  })),
  on(EmployeeActions.insertSystemProfilesSuccess, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, insertSystemProfiles: true },
    updated: true,
  })),
  on(EmployeeActions.insertSystemProfilesFailure, (state, { error }) => ({
    ...state,
    isLoaded: { ...state.isLoaded, insertSystemProfiles: true },
    error,
  })),

  on(EmployeeActions.deletePermissions, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, deletePermissions: false },
  })),
  on(EmployeeActions.deletePermissionsSuccess, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, deletePermissions: true },
    updated: true,
  })),
  on(EmployeeActions.deletePermissionsFailure, (state, { error }) => ({
    ...state,
    isLoaded: { ...state.isLoaded, deletePermissions: true },
    error,
  })),

  on(EmployeeActions.toggleAllEmployees, (state, { isSelected, stBloqueado }) => {
    if (state.filtered) {
      return {
        ...state,
        filteredEmployees: state.filteredEmployees.map((employee) => 
          employee.stBloqueado === stBloqueado 
            ? { ...employee, selected: isSelected } 
            : employee
        ),
      };
    } else {
      return {
        ...state,
        employees: state.employees.map((employee) => 
          employee.stBloqueado === stBloqueado 
            ? { ...employee, selected: isSelected } 
            : employee
        ),
      };
    }
  }),
  on(
    EmployeeActions.toggleEmployee,
    (state, { idUsuarioRepresentante, isSelected, stBloqueado }) => {
      if (state.filtered) {
        return {
          ...state,
          filteredEmployees: state.filteredEmployees.map((employee) =>
            employee.idUsuarioRepresentante === idUsuarioRepresentante &&
            employee.stBloqueado === stBloqueado
              ? { ...employee, selected: isSelected }
              : employee
          ),
        };
      } else {
        return {
          ...state,
          employees: state.employees.map((employee) =>
            employee.idUsuarioRepresentante === idUsuarioRepresentante &&
            employee.stBloqueado === stBloqueado
              ? { ...employee, selected: isSelected }
              : employee
          ),
        };
      }
    }
  ),
  

  on(
    EmployeeActions.editEmployees,
    (state, { employees, isPerfilGestorAnvisa, isPerfilGestorCadastrosResponsavelLegal }) => ({
      ...state,
      // Conditionally filter employees based on the value of isPerfilGestorAnvisa
      selectedEmployees: isPerfilGestorAnvisa || isPerfilGestorCadastrosResponsavelLegal
        ? employees // If isPerfilGestorAnvisa or isPerfilGestorCadastrosResponsavelLegal is true, no filter is applied
        : employees.filter((employee) => employee.stBloqueado === "N"), // Otherwise, filter as before
      updated: false,
      error: null,
    })
  ),

  on(EmployeeActions.insertSystemProfilesBatch, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, insertSystemProfilesBatch: false },
  })),
  on(EmployeeActions.insertSystemProfilesBatchSuccess, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, insertSystemProfilesBatch: true },
    updated: true,
  })),
  on(EmployeeActions.insertSystemProfilesBatchFailure, (state, { error }) => ({
    ...state,
    isLoaded: { ...state.isLoaded, insertSystemProfilesBatch: true },
    error,
  })),

  on(EmployeeActions.deletePermissionsByUsuarioRepresentanteId, (state) => ({
    ...state,
    isLoaded: {
      ...state.isLoaded,
      deletePermissionsByUsuarioRepresentanteId: false,
    },
  })),
  on(
    EmployeeActions.deletePermissionsByUsuarioRepresentanteIdSuccess,
    (state) => ({
      ...state,
      isLoaded: {
        ...state.isLoaded,
        deletePermissionsByUsuarioRepresentanteId: true,
      },
      updated: true,
    })
  ),
  on(
    EmployeeActions.deletePermissionsByUsuarioRepresentanteIdFailure,
    (state, { error }) => ({
      ...state,
      isLoaded: {
        ...state.isLoaded,
        deletePermissionsByUsuarioRepresentanteId: true,
      },
      error,
    })
  ),

  // Triggered when a batch expiration date update is initiated
  on(EmployeeActions.updateExpirationDateBatch, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, updateExpirationDateBatch: false },
  })),

  // Triggered when a batch expiration date update succeeds
  on(
    EmployeeActions.updateExpirationDateBatchSuccess,
    (state, { dtExpiracao }) => ({
      ...state,
      selectedEmployees: state.selectedEmployees.map((employee) => ({
        ...employee,
        dtExpiracao: dtExpiracao, // Set the expiration date to the new value
      })),
      isLoaded: { ...state.isLoaded, updateExpirationDateBatch: true },
      updated: true,
    })
  ),

  // Triggered when a batch expiration date update fails
  on(EmployeeActions.updateExpirationDateBatchFailure, (state, { error }) => ({
    ...state,
    isLoaded: { ...state.isLoaded, updateExpirationDateBatch: true },
    error,
  })),

  // Triggered when a batch toggle block status is initiated
  on(EmployeeActions.toggleUserBlockBatchStatus, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, toggleUserBlockBatchStatus: false },
  })),

  // Triggered when a batch toggle block status succeeds
  on(
    EmployeeActions.toggleUserBlockStatusBatchSuccess,
    (state, { active }) => ({
      ...state,
      selectedEmployees: state.selectedEmployees.map((employee) => ({
        ...employee,
        stBloqueado: active ? "N" : "S", // Adjust if your API uses boolean instead
      })),
      isLoaded: { ...state.isLoaded, toggleUserBlockBatchStatus: true },
      updated: true,
    })
  ),

  // Triggered when a batch toggle block status fails
  on(EmployeeActions.toggleUserBlockStatusBatchFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, toggleUserBlockBatchStatus: true },
  })),

  on(EmployeeActions.clearEditEmployees, (state) => ({
    ...state,
    selectedEmployees: [],
  })),
  
  on(EmployeeActions.setFiltered, (state, { filtered }) => ({
    ...state,
    filtered,
  })),

  on(EmployeeActions.insereColaborador, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, insereColaborador: false },
    updated: false,
  })),
  on(EmployeeActions.insereColaboradorSuccess, (state) => ({
    ...state,
    updated: true,
    isLoaded: { ...state.isLoaded, insereColaborador: true },
  })),
  on(EmployeeActions.insereColaboradorFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, insereColaborador: true },
  })),

  on(EmployeeActions.insereColaboradorInterno, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, insereColaboradorInterno: false },
    updated: false,
  })),
  on(EmployeeActions.insereColaboradorInternoSuccess, (state) => ({
    ...state,
    updated: true,
    isLoaded: { ...state.isLoaded, insereColaboradorInterno: true },
  })),
  on(EmployeeActions.insereColaboradorInternoFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, insereColaboradorInterno: true },
  })),

  on(EmployeeActions.verificarValidadeCPF, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, verificarValidadeCPF: false },
  })),
  on(EmployeeActions.verificarValidadeCPFSuccess, (state, { colaborador }) => ({
    ...state,
    colaboradorSerpro: colaborador,
    isLoaded: { ...state.isLoaded, verificarValidadeCPF: true },
  })),
  on(EmployeeActions.verificarValidadeCPFFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, verificarValidadeCPF: true },
  })),

  on(EmployeeActions.setUpdatedEmployee, (state, { updated }) => ({
    ...state,
    updated,
  })),

  on(EmployeeActions.resetColaboradorSerpro, (state) => ({
    ...state,
    colaboradorSerpro: undefined,
  })),

  on(EmployeeActions.resetAllEmployees, (state) => ({
    ...state,
    employees: []
  }))
);
