import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DashboardOverviewDTO } from '../model/dashboard-overview';
import { DashboardState } from '../interfaces/dashboard-state.interface';
import * as DashboardActions from '../actions/dashboard.actions';
import { selectDashboard } from '../selectors/dashboard.selector';

@Component({
  selector: 'app-dashboard-dialog',
  templateUrl: './dashboard-dialog.component.html',
  styleUrls: ['./dashboard-dialog.component.scss']
})
export class DashboardDialogComponent implements OnInit {
  dashboardData$: Observable<DashboardOverviewDTO | undefined>;

  constructor(
    private store: Store<DashboardState>,
    public dialogRef: MatDialogRef<DashboardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dashboardData$ = this.store.select(selectDashboard);
  }

  ngOnInit(): void {
    // Dispatch the action to load the dashboard data when the dialog opens
    this.store.dispatch(DashboardActions.loadDashboard());
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
