import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as PerfilActions from '../actions/perfil.actions';
import { PerfilService } from '../service/perfil.service';

@Injectable()
export class PerfilEffects {
  loadPerfis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PerfilActions.loadPerfisByUsuarioRepresentante),
      mergeMap((action) =>
        this.perfilService.findSistemaPerfilByUsuarioRepresentante(action.idUsuarioRepresentante).pipe(
          map(perfisCustom => PerfilActions.loadPerfisByUsuarioRepresentanteSuccess({ perfisCustom })),
          catchError(error => of(PerfilActions.loadPerfisByUsuarioRepresentanteFailure({ error })))
        )
      )
    )
  );

  loadPerfisBySistema$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PerfilActions.loadPerfisBySistema),
      mergeMap((action) =>
        this.perfilService.findPerfilBySistema(action.sistemaId).pipe(
          map(perfis => PerfilActions.loadPerfisBySistemaSuccess({ perfis })),
          catchError(error => of(PerfilActions.loadPerfisBySistemaFailure({ error })))
        )
      )
    )
  );

  loadNodeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PerfilActions.loadNodeProfiles),
      mergeMap((action) =>
        this.perfilService.findPerfilByCoSistemaAndIdUsuarioRepresentante(action.coSistema, action.idUsuarioRepresentante).pipe(
          map(profiles => PerfilActions.loadNodeProfilesSuccess({ sistemaId: action.coSistema, perfis: profiles })),
          catchError(error => of(PerfilActions.loadNodeProfilesFailure({ error })))
        )
      )
    )
  );

  loadProfileNodeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PerfilActions.loadColaboradorProfilesByNode),
      mergeMap(({coSistema, idUsuarioRepresentante}) =>
        this.perfilService.findPerfilByCoSistemaAndIdUsuarioRepresentante(coSistema, idUsuarioRepresentante).pipe(
          map(profiles => PerfilActions.loadColaboradorProfilesByNodeSuccess({idUsuarioRepresentante, sistemaId: coSistema, perfis: profiles })),
          catchError(error => of(PerfilActions.loadColaboradorProfilesByNodeFailure({ error })))
        )
      )
    )
  );

  loadPerfisByUsuarioRepresenanteId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PerfilActions.loadPerfisMapByUsuarioRepresentante),
      mergeMap(({idUsuarioRepresentante}) =>
        this.perfilService.findSistemaPerfilByUsuarioRepresentante(idUsuarioRepresentante).pipe(
          map(perfisCustom => PerfilActions.loadPerfisMapByUsuarioRepresentanteSuccess({ idUsuarioRepresentante, perfisCustom })),
          catchError(error => of(PerfilActions.loadPerfisMapByUsuarioRepresentanteFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private perfilService: PerfilService) {}
}
