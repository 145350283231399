import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import * as CompanyActions from '../actions/company.action';
import { PessoaJuridicaService } from '../service/pessoajuridica.service';
import { GovBrService } from '../service/govbr.service';
import { SerproService } from '../service/serpro.service';
import { Porte } from '../model/porte.model';
import { PorteService } from '../service/porte.service';
import { Store } from '@ngrx/store';
import { Functions } from '../util/functions';
import { selectAllCompaniesCachedData } from '../selectors/company.selector';
import { CompanyState } from '../interfaces/company-state.interface';

@Injectable()
export class CompanyEffects {

  constructor(
    private actions$: Actions,
    public pessoaJuridicaService: PessoaJuridicaService,
    private govbrService:GovBrService,
    public serproService: SerproService,
    private porteService: PorteService,
    private store: Store<{ company: CompanyState }>
  ) {
    
  }
  
  loadAllCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(CompanyActions.loadAllCompanies),
    withLatestFrom(this.store.select(selectAllCompaniesCachedData)),
    switchMap(([action, cachedData]) => {
      console.log('Cached Data:', cachedData); // Log the entire cached data object
      if (
          cachedData.allCompanies 
          && cachedData.allCompanies.length > 0 
          && Functions.isCacheValid(cachedData.timestamp)
        ) {
        console.log("Using loadAllCompanies cached data");
        return EMPTY; // No need to fetch, use cached data
      } else {
        return this.pessoaJuridicaService.findAllPessoaJuridicaByCpf().pipe(
          map(companies => CompanyActions.loadAllCompaniesSuccess({ companies })),
          catchError(error => of(CompanyActions.loadAllCompaniesFailure({ error: error.message || 'Unknown error' })))
        );
      }
    })    
  ));

  chooseCompany$ = createEffect(() => this.actions$.pipe(
    ofType(CompanyActions.loadCompany),
    mergeMap((action) =>      
      this.pessoaJuridicaService.findPessoaJuridicaById(action.idPessoaJuridica).pipe(      
        map(company => CompanyActions.loadCompanySuccess({ company })),
        catchError(error => of(CompanyActions.loadCompanyFailure({ error: error.message || 'Unknown error' })))
      )
    )
  ));

  loadEmpresas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadEmpresas),
      mergeMap(({ govBrAccessToken }) =>
        this.govbrService.getEmpresas(govBrAccessToken).pipe(
          map((empresas) => CompanyActions.loadEmpresasSuccess({ empresas })),
          catchError((error) => of(CompanyActions.loadEmpresasFailure({ error })))
        )
      )
    )
  );

  updateEmpresas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.updateEmpresas),
      mergeMap(({ cnpjs }) =>
        this.pessoaJuridicaService.updateEmpresas(cnpjs).pipe(
          map(() => CompanyActions.updateEmpresasSuccess()),
          catchError((error) => of(CompanyActions.updateEmpresasFailure({ error })))
        )
      )
    )
  );

  consultaCNPJ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.consultaCNPJ),
      mergeMap(({ cnpj }) =>
        this.serproService.consultaCNPJ(cnpj).pipe(
          map((data) => CompanyActions.consultaCNPJSuccess({ data })),
          catchError((error) => of(CompanyActions.consultaCNPJFailure({ error })))
        )
      )
    )
  );

  consultaPorte$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.consultaPorte),
      mergeMap(({ codigoPorte }) =>
        this.porteService.findPorteDescricaoById(codigoPorte).pipe(
          map((descricaoPorte) => {
            const porte: Porte = { codigoPorte, descricaoPorte };
            return CompanyActions.consultaPorteSuccess({ porte });
          }),
          catchError((error) => of(CompanyActions.consultaPorteFailure({ error })))
        )
      )
    )
  );

  consultaCNES$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.consultaCNES),
      mergeMap(( action ) =>
        this.pessoaJuridicaService.findInstituicoesByCnes(action.cnes, action.page, action.size).pipe(
          map((companies) => CompanyActions.consultaCNESSuccess({ companies })),
          catchError((error) => of(CompanyActions.consultaCNESFailure({ error })))
        )
      )
    )
  );

  
}
