export class SituacaoCadastralSerpro {
  codigo: string;
  data: string;
  motivo: string;

  /**
   * Construtor da classe.
   * Inicializa a instância com os dados fornecidos ou valores padrão.
   * @param {Partial<SituacaoCadastralSerpro>} dados - Dados opcionais para inicializar as propriedades da instância.
   */
  constructor(dados?: Partial<SituacaoCadastralSerpro>) {
    this.codigo = dados?.codigo ?? '';
    this.data = dados?.data ?? '';
    this.motivo = dados?.motivo ?? '';
  }

  /**
   * Retorna a descrição para um dado 'Código da Situação Cadastral' (CSC).
   * @returns {string} - A descrição correspondente para o código.
   */
  getSituacaoCadastralDesc(): string {
    if (!this.codigo) {
      return "Código não fornecido";
    }

    const cscDesc: Record<string, string> = {
      "01": "Nula",
      "02": "Ativa",
      "03": "Suspensa",
      "04": "Inapta",
      "05": "Ativa Não Regular",
      "08": "Baixada",
    };

    return cscDesc[this.codigo] ? `${cscDesc[this.codigo]}` : `Código desconhecido`;
  }

  /**
   * Retorna a descrição para um dado 'Motivo Situação Cadastral'.
   * @returns {string} - A descrição correspondente para o motivo.
   */
  getMotivoSituacaoDesc(): string {
    if (!this.motivo) {
      return "Motivo não fornecido";
    }

    const motivoDesc: Record<string, string> = {
      "00": "Ausência de Motivo",
      "01": "Extinção Por Encerramento Liquidação Voluntária",
      "02": "Incorporação",
      "03": "Fusão",
      "04": "Cisão Total",
      "05": "Encerramento Da Falência",
      "06": "Encerramento Da Liquidação",
      "07": "Elevação A Matriz",
      "08": "Transpasse",
      "09": "Não Início De Atividade",
      "10": "Extinção Pelo Encerramento Da Liquidação Judicial",
      "11": "Anulação Por Multiciplidade",
      "12": "Anulação Online De Oficio",
      "13": "Omissa Contumaz",
      "14": "Omissa não Localizada",
      "15": "Inexistente De Fato",
      "16": "Anulação Por Vícios",
      "17": "Baixa Iniciada E Ainda não Deferida",
      "18": "Interrupção Temporária Das Atividades",
      "19": "Omisso De Dirpj Até 5 Exercícios",
      "20": "Em Condição De Inaptidão",
      "21": "Pedido De Baixa Indeferida",
      "22": "Restabelecimento Com Certidão Positiva Com Efeito De Negativa",
      "23": "Com Pendência Fiscal",
      "24": "Por Emissão Certidão Negativa",
      "25": "Certidão Positiva Com Efeito De Negativa",
      "26": "Irregularidade De Pagamento",
      "27": "Irregularidade De Recolhimento E Exigibilidade Suspensa",
      "28": "Transferência Filial Condição Matriz",
      "29": "Aguardando Conf. De Dirpj/Dipj",
      "30": "Anr | Aguardando Conf. De Dirpj/Dipj",
      "31": "Extinção Da Filial",
      "32": "Inexistente De Fato – Ade/Cosar",
      "33": "Transferência Do Órgão Local A condição De Filial Do órgão Regional",
      "34": "Anulação De Inscrição Indevida",
      "35": "Empresa Estrangeira Aguardando Documentação",
      "36": "Prática Irregular De Operação De Comercio Exterior",
      "37": "Baixa De Produtor Rural",
      "38": "Baixa Deferida Pela RFB Aguardando Analise Do Convenente",
      "39": "Baixa Deferida Pela RFB E Indeferida Pelo Convenente",
      "40": "Baixa Indeferida Pela RFB E Aguardando Analise Do Convenente",
      "41": "Baixa Indeferida Pela RFB E Deferida Pelo Convenente",
      "42": "Baixa Deferida Pela RFB E Sefin, Aguardando Analise Sefaz",
      "43": "Baixa Deferida Pela RFB, Aguardando Analise Da Sefaz E Indeferida Pela Sefin",
      "44": "Baixa Deferida Pela RFB E Sefaz, Aguardando Analise Sefin",
      "45": "Baixa Deferida Pela RFB, Aguardando Analise Da Sefin E Indeferida Pela Sefaz",
      "46": "Baixa Deferida Pela RFB E Sefaz E Indeferida Pela Sefin",
      "47": "Baixa Deferida Pela RFB E Sefin E Indeferida Pela Sefaz",
      "48": "Baixa Indeferida Pela RFB, Aguardando Analise Sefaz E Deferida Pela Sefin",
      "49": "Baixa Indeferida Pela RFB, Aguardando Analise Da Sefaz E Indeferida Pela Sefin",
      "50": "Baixa Indeferida Pela RFB, Deferida Pela Sefaz E Aguardando Analise Da Sefin",
      "51": "Baixa Indeferida Pela RFB E Sefaz, Aguardando Analise Da Sefin",
      "52": "Baixa Indeferida Pela RFB, Deferida Pela Sefaz E Indeferida Pela Sefin",
      "53": "Baixa Indeferida Pela RFB E Sefaz E Deferida Pela Sefin",
      "54": "Baixa | Tratamento Diferenciado Dado As ME E EPP (Lei Complementar Numero 123/2006)",
      "55": "Deferido Pelo Convenente, Aguardando Analise Da Rfb",
      "60": "Artigo 30, Vi, Da In 748/2007",
      "61": "Indicio Interpos. Fraudulenta",
      "62": "Falta De Pluralidade De Socios",
      "63": "Omissão De Declarações",
      "64": "Localização Desconhecida",
      "66": "Inaptidão",
      "67": "Registro Cancelado",
      "70": "Anulação Por Não Confirmado Ato De Registro Do Mei Na Junta Comercial",
      "71": "Inaptidão (Lei 11.941/2009 Art.54)",
      "72": "Determinação Judicial",
      "73": "Omissão Contumaz",
      "74": "Inconsistência Cadastral",
      "75": "Óbito do MEI | Titular Falecido",
      "80": "Baixa Registrada Na Junta, Indeferida Na Rfb",
      "82": "Suspenso perante a Comissão de Valores Mobiliários - CVM",
    };

    return motivoDesc[this.motivo] ? `${motivoDesc[this.motivo]}` : `Motivo desconhecido`;
  }
}
