import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PessoaJuridica } from 'src/app/model/pessoajuridica.model';
import { PessoaJuridicaService } from 'src/app/service/pessoajuridica.service';
import { Functions } from 'src/app/util/functions';
import { ToastrFunctions } from 'src/app/util/toastr.functions';

@Component({
  selector: 'app-dialog-search-empresa',
  templateUrl: './dialog-search-empresa.component.html'
})
export class DialogSearchEmpresaComponent {

  cnpj = '';
  cnpjSemMascara = "";
  razaoSocial = '';
  pessoaJuridica?: PessoaJuridica;

  isSearchSuccessful = false;

  constructor(
    public pessoaJuridicaService: PessoaJuridicaService,
    public toastr: ToastrService,
    public dialogRef: MatDialogRef<DialogSearchEmpresaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.cnpj = this.data.CNPJ;
      this.cnpjSemMascara = Functions.removeMask(this.data.CNPJ);
      this.pesquisaEmpresa();    
  }

  pesquisaEmpresa() {

    if (!this.cnpjSemMascara.trim()) {
      ToastrFunctions.showWarning(this.toastr, 'Preencha o CNPJ.');
      return;
    }

    
    this.pessoaJuridicaService
    .findPessoaJuridicaByCnpj(this.cnpjSemMascara)
      .pipe()
      .subscribe({
        next: (data: PessoaJuridica) => {          
          this.isSearchSuccessful = !!data;
          if (data) {            
            this.cnpj = data.numeroCNPJ;
            this.razaoSocial = data.razaoSocial;
            this.pessoaJuridica = data;
          } 
        },
        error: (e: HttpErrorResponse) => {
          if (e.status === 404) {
            ToastrFunctions.showError(this.toastr, 'CNPJ não cadastrado na base de dados da ANVISA.');
          } else {
            ToastrFunctions.showError(this.toastr, 'Algum erro ocorreu ao verificar o CNPJ.');
          }
          this.isSearchSuccessful = false;
        },
      });
  }

  selecionaEmpresa() {

    if (!this.cnpj.trim()) {
      ToastrFunctions.showWarning(this.toastr, 'Preencha o CNPJ.');
      return;
    }

    this.dialogRef.close(this.pessoaJuridica);
  }

  reset() {
    this.cnpj = '';
    this.razaoSocial = '';
    this.isSearchSuccessful = false;
    this.pessoaJuridica = undefined;
  }

}
