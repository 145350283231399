import { HttpClientModule } from "@angular/common/http";
import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  isDevMode,
  LOCALE_ID,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  AsyncPipe,
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
  NgFor,
  registerLocaleData,
} from "@angular/common";
import { AppComponent } from "./app.component";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { initializeKeycloak } from "./util/app.init";
import { AppRoutingModule } from "./app-routing.module";
import { HeaderComponent } from "./components/header/header.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ModalModule, BsModalService } from "ngx-bootstrap/modal";
import { ToastrModule } from "ngx-toastr";
import { ColaboradorVincCnpjComponent } from "./colaborador-vinc-cnpj/colaborador-vinc-cnpj.component";
import { MatTableModule } from "@angular/material/table";
import { MatCardModule } from "@angular/material/card";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DialogComponent } from "./components/dialog/dialog.component";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatBadgeModule } from "@angular/material/badge";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DialogAddColaboradorComponent } from "./colaborador-vinc-cnpj/dialog-add-colaborador/dialog-add-colaborador.component";
import { DialogColabAtrPerfilComponent } from "./colaborador-vinc-cnpj/dialog-colab-atr-perfil/dialog-colab-atr-perfil.component";
//import { perfilResolver } from './resolver/perfilResolver';
import { RouterModule } from "@angular/router";
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideEnvironmentNgxMask,
} from "ngx-mask";
import { DialogSearchEmpresaComponent } from "./colaborador-vinc-cnpj/dialog-search-empresa/dialog-search-empresa.component";
import { IntegradorComponent } from "./integrador/integrador.component";
import { AddIntegrationDialogComponent } from "./integrador/add-integration-dialog/add-integration-dialog.component";
import { ViewIntegrationDialogComponent } from "./integrador/view-integration-dialog/view-integration-dialog.component";
import { DialogAddPerfilSistemaColabComponent } from "./colaborador-vinc-cnpj/dialog-add-perfil-sistema-colab/dialog-add-perfil-sistema-colab.component";
import { DialogSelectEmpresaComponent } from "./colaborador-vinc-cnpj/dialog-select-empresa/dialog-select-empresa.component";
import { DialogSearchInstituicaoComponent } from "./colaborador-vinc-cnpj/dialog-search-instituicao/dialog-search-instituicao.component";
import { MatTreeModule } from "@angular/material/tree";
import { DialogColabAtrPerfilLoteComponent } from "./colaborador-vinc-cnpj/dialog-colab-atr-perfil-lote/dialog-colab-atr-perfil-lote.component";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import { provideStore } from "@ngrx/store";
import { provideEffects } from "@ngrx/effects";
import { companyReducer } from "./reducers/company.reducer";
import { CompanyEffects } from "./effects/company.effect";
import { EmployeeEffects } from "./effects/employee.effect";
import { employeeReducer } from "./reducers/employee.reducer";
import { LoginComponent } from "./login/login.component";
import { NotificationComponent } from "./notification/notification.component";
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";
import { LogComponent } from "./log/log.component";
import { logReducer } from "./reducers/log.reducer";
import { LogEffects } from "./effects/log.effect";
import { SistemaEffects } from "./effects/sistema.effect";
import { PerfilEffects } from "./effects/perfil.effect";
import { sistemaReducer } from "./reducers/sistema.reducer";
import { perfilReducer } from "./reducers/perfil.reducer";
import { HeaderNotLoggedComponent } from "./components/header-not-logged/header-not-logged.component";
import { authReducer } from "./reducers/auth.reducer";
import { AuthEffects } from "./effects/auth.effect";
import { HomeComponent } from "./home/home.component";
import { GestaoPerfilColabInterComponent } from "./gestao-perfil-colab-inter/gestao-perfil-colab-inter.component";
import { DialogAddPerfilColabComponent } from "./gestao-perfil-colab-inter/dialog-add-perfil-colab/dialog-add-perfil-colab.component";
import { perfilInternoReducer } from "./reducers/perfilinterno.reducer";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { DialogLoadEmpresasComponent } from "./home/dialog-load-empresas/dialog-load-empresas.component";
import {
  CustomDateAdapter,
  MY_DATE_FORMATS,
} from "./shared/custom-date-format";
import localePt from '@angular/common/locales/pt';
import { PerfilInternoEffects } from "./effects/perfilinterno.effect";
import { MatPaginatorIntlPtBR } from "./shared/mat-paginator-pt-br";
import { DashboardDialogComponent } from './dashboard-dialog/dashboard-dialog.component';
import { dashboardReducer } from "./reducers/dashboard.reducer";
import { DashboardEffects } from "./effects/dashboard.effect";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DialogColaboradorInternoSemAcessoComponent } from './home/dialog-colaborador-interno-sem-acesso/dialog-colaborador-interno-sem-acesso.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

registerLocaleData(localePt); // Registra os dados de localidade


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BreadcrumbComponent,
    FooterComponent,
    ColaboradorVincCnpjComponent,
    DialogComponent,
    DialogAddPerfilSistemaColabComponent,
    DialogAddColaboradorComponent,
    DialogColabAtrPerfilComponent,
    DialogSelectEmpresaComponent,
    DialogSearchEmpresaComponent,
    IntegradorComponent,
    AddIntegrationDialogComponent,
    ViewIntegrationDialogComponent,
    DialogSearchInstituicaoComponent,
    DialogColabAtrPerfilLoteComponent,
    LoginComponent,
    NotificationComponent,
    LoadingSpinnerComponent,
    LogComponent,
    HeaderNotLoggedComponent,
    HomeComponent,
    GestaoPerfilColabInterComponent,
    DialogAddPerfilColabComponent,
    DialogLoadEmpresasComponent,
    DashboardDialogComponent,
    DialogColaboradorInternoSemAcessoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HttpClientModule,
    ModalModule,
    ToastrModule.forRoot(),
    MatTableModule,
    MatCardModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgFor,
    MatSelectModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    BrowserAnimationsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule
  ],
  providers: [
    provideEnvironmentNgxMask(maskConfig),
    provideStore({
      company: companyReducer,
      employees: employeeReducer,
      sistemas: sistemaReducer,
      perfis: perfilReducer,
      logs: logReducer,
      auth: authReducer,
      perfisInterno: perfilInternoReducer,
      dashboard: dashboardReducer
    }),
    provideEffects([
      CompanyEffects,
      EmployeeEffects,
      SistemaEffects,
      PerfilEffects,
      LogEffects,
      SistemaEffects,
      PerfilEffects,
      AuthEffects,
      PerfilInternoEffects,
      DashboardEffects
    ]),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    BsModalService,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" }, // Defina o locale como pt-BR
    { provide: LOCALE_ID, useValue: 'pt-BR' }, // Configura o locale para pt-BR no Angular
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlPtBR }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
