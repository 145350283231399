import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { Observable, ObservableInput, catchError, map, throwError } from "rxjs";
import { Permissao } from "../model/permissao.model";
import { ColaboradoresCustom } from "../model/colaboradorescustom.model";
import { ColaboradorCustom } from "../model/colaboradorcustom";
import { SistemaPerfisColaboradorRequest } from "../model/sistemaperfiscolaboradorrequest.model";
import { ColaboradorInternoCustom } from "../model/colaboradorinternocustom";

@Injectable({
  providedIn: "root",
})
export class ColaboradorService {
  public readonly API = `${environment.API}/api/v1/colaboradores`;
  private token: unknown;
  error:
    | ((err: any, caught: Observable<object>) => ObservableInput<any>)
    | undefined;

  constructor(
    private httpClient: HttpClient,
    private readonly keycloackService: KeycloakService
  ) {}

  getToken() {
    return this.keycloackService.getToken();
  }

  findUsuarioByPessoaJuridicaId(
    pessoaJuridicaId: string | undefined,
    page: number,
    size: number
  ) {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const requestOptions = { headers: headers };
    return this.httpClient
      .get<ColaboradoresCustom>(
        this.API +
          "/find-usuario-by-pessoa-juridica-id/" +
          pessoaJuridicaId +
          "?page=" +
          page +
          "&size=" +
          size,
        requestOptions
      )
      .pipe(map((res) => res));
  }

  findAllUsuariosByPessoaJuridicaId(
    pessoaJuridicaId: string | null,
    stBloqueado?: string
  ) {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });

    // Construct the API URL
  const url = `${this.API}/find-all-usuarios-by-pessoa-juridica-id/${pessoaJuridicaId}`;

  // Initialize HttpParams and add 'stBloqueado' if provided
  let params = new HttpParams();
  if (stBloqueado) {
    params = params.set('stBloqueado', stBloqueado);
  }

    // Execute the GET request with headers and optional query parameters
    return this.httpClient
      .get<ColaboradorCustom[]>(url, { headers, params })
      .pipe(map((res) => res));
  }

  updateUsuario(data: any): Observable<any> {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const API_URL = `${this.API}/atualiza-usuario`;
    return this.httpClient.put(API_URL, data, { headers: headers });
  }

  updateUsuariosEmLote(data: any[]): Observable<any> {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const API_URL = `${this.API}/atualiza-usuarios-em-lote`;
    return this.httpClient.put(API_URL, data, { headers: headers });
  }

  insereSistemaPerfilUsuario(
    data: SistemaPerfisColaboradorRequest
  ): Observable<any> {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const API_URL = `${this.API}/insere-sistema-perfis-usuario`;
    return this.httpClient.post(API_URL, data, { headers: headers });
  }

  atualizaStAprovadoPermissao(data: Permissao): Observable<any> {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const API_URL = `${this.API}/atualiza-st-aprovado-permissao`;
    return this.httpClient.put(API_URL, data, { headers: headers });
  }

  atualizaStAprovadoPermissaoEmLote(data: Permissao[]): Observable<any> {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const API_URL = `${this.API}/atualiza-st-aprovado-permissao-em-lote`;
    return this.httpClient.put(API_URL, data, { headers: headers });
  }

  deletePermissao(
    idUsuarioRepresentante: string,
    idsPerfis: string[]
  ): Observable<string> {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const API_URL = `${this.API}/delete-permissao`;

    const params = new HttpParams()
      .set("idUsuarioRepresentante", idUsuarioRepresentante)
      .set("idsPerfis", idsPerfis.join(",")); // Assuming the server can handle comma-separated values

    return this.httpClient
      .delete<string>(API_URL, {
        headers,
        params,
        responseType: "text" as "json",
      })
      .pipe(map((response) => response));
  }

  atualizaDtExpiracao(
    idUsuarioRepresentante: string,
    dtExpiracao: string | null
  ): Observable<any> {
    const api_key = this.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });

    let params = new HttpParams().set(
      "idUsuarioRepresentante",
      idUsuarioRepresentante
    );
    if (dtExpiracao !== null && dtExpiracao !== undefined) {
      params = params.set("dtExpiracao", dtExpiracao);
    }

    const options = {
      headers: headers,
      params: params,
    };

    const API_URL = `${this.API}/atualiza-dt-expiracao`;

    return this.httpClient.put(API_URL, {}, options);
  }

  atualizaDtExpiracaoEmLote(
    idUsuariosRepresentantes: string[],
    dtExpiracao: string | null
  ): Observable<any> {
    const api_key = this.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });

    let params = new HttpParams();
    idUsuariosRepresentantes.forEach((id) => {
      params = params.append("idUsuariosRepresentantes", id);
    });

    if (dtExpiracao !== null && dtExpiracao !== undefined) {
      params = params.set("dtExpiracao", dtExpiracao);
    }

    const options = {
      headers: headers,
      params: params,
    };

    const API_URL = `${this.API}/atualiza-dt-expiracao-em-lote`;

    return this.httpClient.put(API_URL, {}, options);
  }

  insereColaborador(pessoaJuridicaId: string, data: any): Observable<string> {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const API_URL = `${this.API}/insere-usuario/${pessoaJuridicaId}`;
    return this.httpClient
      .post<string>(API_URL, data, {
        headers: headers,
        responseType: "text" as "json",
      })
      .pipe(map((response) => response));
  }

  insereColaboradorInterno(data: any): Observable<string> {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const API_URL = `${this.API}/insere-usuario-interno`;
    return this.httpClient
      .post<string>(API_URL, data, {
        headers: headers,
        responseType: "text" as "json",
      })
      .pipe(map((response) => response));
  }

  adicionaPermissaoColaboradorLote(
    idsUsuarioRepresentante: string[],
    idPerfil: string,
    cnpjsOrigem: string[]
  ): Observable<any> {
    this.token = this.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    });

    let params = new HttpParams()
      .set("idsUsuarioRepresentante", idsUsuarioRepresentante.join(","))
      .set("idPerfil", idPerfil);

    // Check if cnpjsOrigem is provided before setting it
    if (cnpjsOrigem && cnpjsOrigem.length) {
      params = params.set("cnpjsOrigem", cnpjsOrigem.join(","));
    }

    const API_URL = `${this.API}/adiciona-permissao-colaborador-lote`;

    return this.httpClient
      .put(API_URL, {}, { headers: headers, params: params })
      .pipe(
        catchError((error) => {
          console.error("Error during the request:", error);
          return throwError(() => new Error("Error during the request"));
        })
      );
  }

  deletaPermissaoColaboradorLote(
    idsUsuarioRepresentante: string[],
    idPerfil: string
  ): Observable<string> {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const API_URL = `${this.API}/delete-permissao-colaborador-lote`;

    const params = new HttpParams()
      .set("idsUsuarioRepresentante", idsUsuarioRepresentante.join(","))
      .set("idPerfil", idPerfil);

    return this.httpClient
      .delete<string>(API_URL, {
        headers,
        params,
        responseType: "text" as "json",
      })
      .pipe(map((response) => response));
  }

  checarPerfisEscritaExistem(
    idPessoaRepresentada: string | null
  ): Observable<boolean> {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });

    return this.httpClient
      .get<boolean>(
        `${this.API}/checar-perfis-escrita/${idPessoaRepresentada}`,
        {
          headers: headers,
        }
      )
      .pipe(map((res) => res));
  }

  findUsuarioByPessoaJuridicaIdAndIdUsuarioRepresentante(
    pessoaJuridicaId: string,
    idUsuarioRepresentante: string
  ) {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });

    const params = new HttpParams()
      .set("pessoaJuridicaId", pessoaJuridicaId)
      .set("idUsuarioRepresentante", idUsuarioRepresentante);

    const requestOptions = { headers: headers, params: params };
    return this.httpClient
      .get<ColaboradorCustom>(
        this.API +
          "/find-usuario-by-pessoa-juridica-id-and-id-usuario-representante",
        requestOptions
      )
      .pipe(map((res) => res));
  }

  findUsuarioInternoByIdUsuarioRepresentante(idUsuarioRepresentante: string) {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });

    const params = new HttpParams().set(
      "idUsuarioRepresentante",
      idUsuarioRepresentante
    );

    const requestOptions = { headers: headers, params: params };
    return this.httpClient
      .get<ColaboradorInternoCustom>(
        this.API + "/find-usuario-interno-by-id-usuario-representante",
        requestOptions
      )
      .pipe(map((res) => res));
  }
}
