import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
import { GovBrToken } from '../model/govbrtoken';
import { GovBrEmpresa } from '../model/govbrempresa';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class GovBrService {

  private readonly AUTH_SERVER = `${environment.AUTH_SERVER}`;
  private readonly API = `${environment.API}/api`;
    
  constructor(
    private httpClient: HttpClient,
    private readonly keycloackService: KeycloakService
  ) {}

  getGovBrAccessToken() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    const requestOptions = { headers: headers};
    const url = `${environment.AUTH_SERVER}realms/${environment.REALM}/broker/govbr/token`;

    const ret = this.httpClient.get<GovBrToken>(url, requestOptions).pipe(map((res) => res));
    console.log("ret: " + JSON.stringify(ret));
    return ret;
  }

  getEmpresas(govBrAccessToken: string) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    const requestOptions = { headers: headers};
    return this.httpClient.get<GovBrEmpresa[]>(this.API + '/v1/govbr/consultas-empresas?govBrAccessToken='+govBrAccessToken, requestOptions).pipe(map((res) => res));
  } 

}
