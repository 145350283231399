import { GovBrEmpresa } from "./govbrempresa";
import { Perfil } from "./perfil.model";

export class UsuarioLogado {
  preferredUsername: string;
  email: string;
  givenName: string;
  familyName: string;
  perfis: Perfil[];
  token!: string; //bearer token
  empresas: GovBrEmpresa[];
  selectedProfile: string;
  roles: string[];

  constructor(preferredUsername: string, email: string, givenName: string, familyName: string, perfis: Perfil[], token: string, empresas: GovBrEmpresa[], selectedProfile: string, roles: string[]) {
    this.preferredUsername = preferredUsername;
    this.email = email;
    this.givenName = givenName;
    this.familyName = familyName;
    this.perfis = perfis;
    this.token = token;
    this.empresas = empresas;
    this.selectedProfile = selectedProfile;
    this.roles = roles
  }

}
