import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  currentYear: number;
  appVersion = environment.APP_VERSION;

  constructor() {
    this.currentYear = new Date().getFullYear();
  }

}
