// src/app/store/actions/auth.actions.ts
import { createAction, props } from '@ngrx/store';
import { GovBrToken } from '../model/govbrtoken';
import { GenericPerfil } from '../model/generic/perfil.base';

export const loadPerfis = createAction(
  '[Auth] Load Perfis'
);
export const loadPerfisSuccess = createAction(
  '[Auth] Load Perfis Success',
  props<{ perfis: GenericPerfil[] }>()
);
export const loadPerfisFailure = createAction(
  '[Auth] Load Perfis Failure',
  props<{ error: any }>()
);

export const checkWritePermission = createAction(
  '[Auth] Check Write Permission',
  props<{idPessoaRepresentada: string | null }>()
);
export const checkWritePermissionSuccess = createAction(
  '[Auth] Check Write Permission Success',
  props<{ hasPermission: boolean }>()
);
export const checkWritePermissionFailure = createAction(
  '[Auth] Check Write Permission Failure',
  props<{ error: any }>()
);

export const logout = createAction('[Auth] Logout');
export const logoutSuccess = createAction('[Auth] Logout Success');

export const loadGovBrAccessToken = createAction(
  '[Auth] Load GovBr Access Token'
);

export const loadGovBrAccessTokenSuccess = createAction(
  '[Auth] Load GovBr Access Token Success',
  props<{ token: GovBrToken }>()
);

export const loadGovBrAccessTokenFailure = createAction(
  '[Auth] Load GovBr Access Token Failure',
  props<{ error: any }>()
);

export const setSelectedProfile = createAction(
  '[Auth] Set Selected Profile',
  props<{ profile: string }>()
);

export const setWritePermission = createAction(
  '[Auth] Set Write Permission',
  props<{ hasPermission: boolean }>()
);
