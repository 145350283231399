import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { UsuarioLogado } from '../model/usuariologado.model';
import { Observable, of } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private secretKey = environment.CRYPTO_SECRET_KEY;

  setItem(key: string, value: any): void {
    try {
      const encryptedValue = CryptoJS.AES.encrypt(
        JSON.stringify(value),
        this.secretKey
      ).toString();      
      sessionStorage.setItem(key, encryptedValue);
    } catch (e) {
      console.error('Error setting item in session storage', e);
    }
  }

  getItem(key: string): any {
    try {
      const encryptedValue = sessionStorage.getItem(key);
      if (encryptedValue) {
        const bytes = CryptoJS.AES.decrypt(encryptedValue, this.secretKey);
        const decryptedValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedValue;
      }
      return null;
    } catch (e) {
      console.error('Error getting item from session storage', e);
      return null;
    }
  }

  setUsuarioLogadoIntoSession(usuarioLogado: UsuarioLogado): void {
    try {
      this.setItem('usuarioLogado', JSON.stringify(usuarioLogado));
    } catch (e) {
      console.error('Error saving to sessionStorage', e);
    }
  }

  public getUsuarioLogadoFromSession(): Observable<UsuarioLogado | null> {
    try {
      const data = this.getItem('usuarioLogado');
      return of(data);      
    } catch (e) {
      console.error('Error reading from sessionStorage', e);
      return of(null);
    }
  }

  removeItem(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      console.error('Error removing item from session storage', e);
    }
  }

  clear(): void {
    try {
      sessionStorage.clear();
    } catch (e) {
      console.error('Error clearing session storage', e);
    }
  }
  
}
