import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as DashboardActions from '../actions/dashboard.actions';
import { DashboardService } from '../service/dashboard.service';

@Injectable()
export class DashboardEffects {
  loadSistemas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadDashboard),
      mergeMap(() =>
        this.dashboardService.getDashboardOverview().pipe(
          map(dashboard => DashboardActions.loadDashboardSuccess({ dashboard })),
          catchError(error => of(DashboardActions.loadDashboardFailure({ error })))
        )
      )
    )
  );


  constructor(private actions$: Actions, private dashboardService: DashboardService) {}
}
