export class Constants {
    
    /* Perfis */
    static readonly PERFIL_GESTOR_ANVISA = 'GESTOR_ANVISA';
    static readonly PERFIL_GESTOR_ANVISA_CONSULTA = 'GESTOR_ANVISA_CONSULTA';
    static readonly PERFIL_GESTOR_CADASTROS = 'Gestor de Cadastros';
    static readonly PERFIL_RESPONSAVEL_LEGAL = 'Responsável Legal';
    static readonly PERFIL_ADMINISTRADOR = 'ADMINISTRADOR';

    static readonly CPF_DESCONHECIDO = "00000000000"

    static readonly CNPJ_ANVISA = "03112388000111";

    static readonly HOME_PAGE = "/home";
      
}