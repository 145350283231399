import { createReducer, on } from "@ngrx/store";
import * as DashboardActions from "../actions/dashboard.actions";
import { DashboardState } from "../interfaces/dashboard-state.interface";

export const initialState: DashboardState = {
  dashboard: undefined,
  error: null,
  isLoaded: {
    loadDashboard: true
  }
};

export const dashboardReducer = createReducer(
  initialState,
  on(DashboardActions.loadDashboard, state => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadDashboard: false }
  })),

  on(DashboardActions.loadDashboardSuccess, (state, { dashboard }) => ({
    ...state,
    dashboard,
    isLoaded: { ...state.isLoaded, loadDashboard: true }
  })),
  on(DashboardActions.loadDashboardFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, loadDashboard: true }
  })),

  on(DashboardActions.clearDashboardState, () => ({
    ...initialState,
    isLoaded: {
      loadDashboard: true
    }
  }))
);
