import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ColaboradorCustom } from "../model/colaboradorcustom";
import { Observable, Subject, Subscription, takeUntil } from "rxjs";
import { Store, select } from "@ngrx/store";
import { selectAllEmployees } from "../selectors/employee.selector";
import { FormControl, FormGroup } from "@angular/forms";
import { Functions } from "../util/functions";
import { ToastrFunctions } from "../util/toastr.functions";
import { ToastrService } from "ngx-toastr";
import { chooseLogs } from "../actions/log.action";
import { Log } from "../model/log";
import { selectAllLogs } from "../selectors/log.selector";
import { BaseComponent } from "../util/base.component";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "../interfaces/app-state.interface";
import { Logs } from "../model/logs";
import { ResetService } from "../service/reset.service";
import {
  loadAllEmployees,
  resetAllEmployees,
} from "../actions/employee.action";
import { EnableTxtNoPessoaFisicaService } from "../service/enabletxtnopessoafisica.service";
import { Constants } from "../util/constants";
import { selectProfile } from "../selectors/auth.selectors";
import { SessionStorageService } from "../service/sessionstorage.service";

@Component({
  selector: "app-log",
  templateUrl: "./log.component.html",
  styleUrls: ["./log.component.scss"],
})
export class LogComponent extends BaseComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    "nomeLogin",
    "dataHora",
    "acao",
    "beneficiario",
    "sistemaPerfil",
    "dataAtivacao",
    "empresaCnpj",
  ];
  dataSource: MatTableDataSource<Log> = new MatTableDataSource<Log>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageSizes: number[] = [5, 10, 20];
  totalElements = 0;

  allEmployees$: Observable<ColaboradorCustom[]>;

  selectedNoPessoaFisicaGroup: any;
  selectedNoPessoaFisicaFormControl = new FormControl();
  selectedNoPessoaFisica = "";
  selectedTxtNoPessoaFisica = "";
  selectedPeriodo: number | null = null; // Changed to number
  logs$!: Observable<Logs | null>;
  logsSubscription!: Subscription;
  isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador =
    false;
  private readonly destroy$ = new Subject<void>();
  private resetSubscription!: Subscription;
  private enableTxtNoPessoaFisicaSubscription!: Subscription;
  enableTxtNoPessoaFisica!: boolean;

  hasNoPessoaFisicaError = false;
  hasPeriodoError = false;

  constructor(
    public store: Store<AppState>,
    public toastr: ToastrService,
    public activatedRoute: ActivatedRoute,
    private resetService: ResetService,
    private enableTxtNoPessoaFisicaService: EnableTxtNoPessoaFisicaService,
    private sessionStorageService: SessionStorageService
  ) {
    super();
    this.allEmployees$ = this.store.pipe(select(selectAllEmployees));
    this.logs$ = this.store.pipe(select(selectAllLogs));
    this.selectedNoPessoaFisicaGroup = new FormGroup({
      selectedNoPessoaFisicaFormControl: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.resetSubscription = this.resetService.reset$.subscribe(() => {
      this.reset();
    });
    this.verificarPerfis();
    this.logs$.pipe(takeUntil(this.destroy$)).subscribe((logs) => {
      if (logs) {
        const shouldResetPaginator = this.totalElements !== logs.totalElements;
        this.dataSource.data = logs.content;
        this.totalElements = logs.totalElements;

        // Update the paginator's page size and length
        this.paginator.pageSize = logs.size;

        if (shouldResetPaginator) {
          // Reset paginator to the first page if total elements have changed
          this.paginator.firstPage();
        }
      } else {
        this.dataSource.data = []; // Ensure table is cleared if data is null
      }
    });

    this.configNoPessoaFisica();
    this.enableTxtNoPessoaFisicaSubscription =
      this.enableTxtNoPessoaFisicaService.enableTxtNoPessoaFisicaSubject$.subscribe(
        () => {
          this.configNoPessoaFisica();
        }
      );

    this.store
      .select(selectProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.reset();
      });
  }

  configNoPessoaFisica() {
    const selectedProfile =
      Functions.getUsuarioLogadoFromSession().selectedProfile;

    if (
      selectedProfile !== Constants.PERFIL_GESTOR_ANVISA &&
      selectedProfile !== Constants.PERFIL_GESTOR_ANVISA_CONSULTA &&
      selectedProfile !== Constants.PERFIL_ADMINISTRADOR
    ) {
      this.enableTxtNoPessoaFisica = false;
    } else {
      this.enableTxtNoPessoaFisica = true;
    }
  }

  private verificarPerfis(): void {
    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ perfis }) => {
        this.resetRoles();

        this.isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador =
          super.checkPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador(
            perfis
          );
      });
  }

  resetRoles(): void {
    this.isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador =
      false;
  }
  doSearch(pageIndex = 0, pageSize = 10): void {
    // Determine the value of noPessoaFisica based on enableTxtNoPessoaFisica
    const noPessoaFisica = this.enableTxtNoPessoaFisica
      ? this.selectedTxtNoPessoaFisica
      : this.selectedNoPessoaFisica;

    // Reset error states
    this.hasNoPessoaFisicaError = false;
    this.hasPeriodoError = false;

    // Initialize an empty string to store error messages
    let errorMessage = "";

    // Validate if noPessoaFisica is empty
    if (Functions.isEmpty(noPessoaFisica)) {
      this.hasNoPessoaFisicaError = true; // Set error flag for Colaborador

      // Check enableTxtNoPessoaFisica to determine the appropriate error message
      if (this.enableTxtNoPessoaFisica) {
        errorMessage += "Informe o nome de um Colaborador."; // For text input case
      } else {
        errorMessage += "Selecione um Colaborador."; // For dropdown selection case
      }
    }

    // Validate if selectedPeriodo is not valid (i.e., null or undefined)
    if (this.selectedPeriodo === null || this.selectedPeriodo === undefined) {
      this.hasPeriodoError = true; // Set error flag for Period
      errorMessage += "\nSelecione um Período."; // Append error message for Period
    }

    // Stop further execution if any errors exist and show the message
    if (this.hasNoPessoaFisicaError || this.hasPeriodoError) {
      ToastrFunctions.showWarning(this.toastr, errorMessage.trim()); // Show combined error message
      return;
    }

    // Validate if selectedTxtNoPessoaFisica has at least 3 characters (when enabled)
    if (
      this.enableTxtNoPessoaFisica &&
      this.selectedTxtNoPessoaFisica.length < 3
    ) {
      ToastrFunctions.showWarning(
        this.toastr,
        "Informe um nome válido com pelo menos 3 caracteres para pesquisar colaboradores."
      );
      this.hasNoPessoaFisicaError = true;
      return;
    }

    // Determine if we should join with PessoaJuridica based on enableTxtNoPessoaFisica
    const joinPessoaJuridica =
      !this.enableTxtNoPessoaFisica && !!this.selectedNoPessoaFisica;

    // Get the saved ID of PessoaJuridica from sessionStorage if joinPessoaJuridica is true
    const savedIdPessoaJuridica = this.sessionStorageService.getItem(
      "selectedIdPessoaJuridica"
    );
    const idPessoaJuridica = joinPessoaJuridica ? savedIdPessoaJuridica : null;

    // Ensure intervalo is a number by providing a fallback default value if selectedPeriodo is null
    const intervalo =
      this.selectedPeriodo !== null && this.selectedPeriodo !== undefined
        ? this.selectedPeriodo
        : 7; // Default to 1 week if no period is selected

    // Dispatch the action to trigger the search
    this.store.dispatch(
      chooseLogs({
        noPessoaFisica,
        joinPessoaJuridica,
        idPessoaJuridica,
        intervalo: intervalo, // Ensure this is valid
        page: pageIndex,
        size: pageSize,
      })
    );
  }

  formatCnpj(cnpj: string) {
    return Functions.formatCnpj(cnpj);
  }

  formatAction(type: string): string {
    if (Functions.isEmpty(type)) {
      return "Não Definido";
    }

    switch (type.toUpperCase()) {
      case "ADICIONAR_COLABORADOR":
        return "Adicionou Colaborador";
      case "ADICIONAR_PESSOA_JURIDICA":
        return "Adicionou Pessoa Jurídica";
      case "ADICIONAR_PESSOA_FISICA":
        return "Adicionou Pessoa Física";
      case "ADICIONAR_PESSOA":
        return "Adicionou Pessoa";
      case "ADICIONAR_USUARIO_REPRESENTANTE":
        return "Adicionou Usuário Representante";
      case "ADICIONAR_REPRESENTANTE":
        return "Adicionou Representante";
      case "ADICIONAR_PERMISSAO":
        return "Adicionou Permissão";
      case "REMOVER_USUARIO_REPRESENTANTE":
        return "Removeu Usuário Representante";
      case "REMOVER_REPRESENTANTE":
        return "Removeu Representante";
      case "REMOVER_PERMISSAO":
        return "Removeu Permissão";
      case "ATUALIZAR_COLABORADOR_PARA_ATIVO":
        return "Atualizou Colaborador para Ativo";
      case "ATUALIZAR_COLABORADOR_PARA_INATIVO":
        return "Atualizou Colaborador para Inativo";
      case "ATUALIZAR_PERMISSAO_PARA_ATIVA":
        return "Atualizou Permissão para Ativa";
      case "ATUALIZAR_PERMISSAO_PARA_INATIVA":
        return "Atualizou Permissão para Inativa";
      case "ATUALIZAR_DATA_INATIVACAO":
        return "Atualizou Data de Inativação";
      case "ATUALIZAR_USUARIO_REPRESENTANTE_PARA_ATIVO":
        return "Atualizou Usuário Representante para Ativo";
      case "ATUALIZAR_USUARIO_REPRESENTANTE_PARA_INATIVO":
        return "Atualizou Usuário Representante para Inativo";
      default:
        return "Não Definido";
    }
  }

  nextPage(event: PageEvent): void {
    this.doSearch(event.pageIndex, event.pageSize);
  }

  reset() {
    this.selectedNoPessoaFisica = "";
    this.selectedTxtNoPessoaFisica = "";
    this.selectedPeriodo = null;
    this.dataSource.data = [];
    this.totalElements = 0;
    // Dispatch resetAllEmployees action to reset the employees state
    this.store.dispatch(resetAllEmployees());

    this.hasNoPessoaFisicaError = false;
    this.hasPeriodoError = false;

    const selectedProfile =
      Functions.getUsuarioLogadoFromSession().selectedProfile;

    if (
      selectedProfile !== Constants.PERFIL_GESTOR_ANVISA &&
      selectedProfile !== Constants.PERFIL_GESTOR_ANVISA_CONSULTA &&
      selectedProfile !== Constants.PERFIL_ADMINISTRADOR
    ) {
      const savedIdPessoaJuridica = this.sessionStorageService.getItem(
        "selectedIdPessoaJuridica"
      );

      this.store.dispatch(
        loadAllEmployees({ idPessoaJuridica: savedIdPessoaJuridica })
      );
    }
  }

  ngOnDestroy(): void {
    if (this.logsSubscription) {
      this.logsSubscription.unsubscribe();
    }
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
    if (this.enableTxtNoPessoaFisicaSubscription) {
      this.enableTxtNoPessoaFisicaSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
