import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { editInternalEmployee } from 'src/app/actions/employee.action';
import { loadPerfilInterno } from 'src/app/actions/perfilinterno.action';
import { AppState } from 'src/app/interfaces/app-state.interface';
import { ColaboradorInternoCustom } from 'src/app/model/colaboradorinternocustom';
import { PerfilInterno } from 'src/app/model/perfilinterno';
import { selectInternalEmployee } from 'src/app/selectors/employee.selector';
import { selectAllPerfilInterno } from 'src/app/selectors/perfilinterno.selector';
import { Functions } from 'src/app/util/functions';

@Component({
  selector: 'app-dialog-add-perfil-colab',
  templateUrl: './dialog-add-perfil-colab.component.html',
  styleUrls: ['./dialog-add-perfil-colab.component.scss']
})
export class DialogAddPerfilColabComponent implements OnInit, OnDestroy {

  perfis = new FormControl<PerfilInterno[]>([]);
  perfilList: PerfilInterno[] = [];
  selectedInternalUser$: Observable<ColaboradorInternoCustom | undefined>;
  perfisInterno$!: Observable<PerfilInterno[]>;
  nuCpf!: string;
  username!: string;
  selectedPerfis: PerfilInterno[] = [];
  private destroy$ = new Subject<void>();
  active = true;

  constructor(
    public activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<DialogAddPerfilColabComponent> // Inject MatDialogRef to control the dialog
  ) {
    this.selectedInternalUser$ = this.store.select(selectInternalEmployee);
    this.perfisInterno$ = this.store.pipe(select(selectAllPerfilInterno));
  }

  ngOnInit() {
    this.loadInitialData();
    
    combineLatest([this.perfisInterno$, this.selectedInternalUser$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([perfis, user]) => {
        if (perfis) {
          this.perfilList = perfis;
        }
        if (user) {
          this.active = user.stBloqueado === "N" ? true : false;          
          this.updateSelectedPerfis(user.perfisInterno);
        }
      });
  }

  maskCpf(str: string) {
    return Functions.maskCPF(str);
  }

  updateSelectedPerfis(perfisInternoFromUser: PerfilInterno[]) {
    if (this.perfilList) {
      this.selectedPerfis = [];
      this.perfilList.forEach(perfil => {
        const selected = perfisInternoFromUser.some(selectedPerfil => selectedPerfil.id === perfil.id);
        if (selected) {
          this.checkItem(perfil);
        } else {
          this.uncheckItem(perfil);
        }
      })
    }    
  }

  checkItem(item: PerfilInterno) {
    if (!this.selectedPerfis.includes(item)) {
      this.selectedPerfis.push(item);      
    }
  }

  uncheckItem(item: PerfilInterno) {
    const index = this.selectedPerfis.indexOf(item);
    if (index >= 0) {
      this.selectedPerfis.splice(index, 1);
    }
  }

  loadInitialData() {
    this.username = this.data.username;
    this.store.dispatch(editInternalEmployee({ coUsername: this.username }));  
    this.store.dispatch(loadPerfilInterno());
  }

  reset() {
    this.active = true;
    this.selectedPerfis = [];
  }

  normalizeString(input: string): string {
    return Functions.normalizeString(input);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
