import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-colaborador-interno-sem-acesso',
  templateUrl: './dialog-colaborador-interno-sem-acesso.component.html',
  styleUrls: ['./dialog-colaborador-interno-sem-acesso.component.scss']
})
export class DialogColaboradorInternoSemAcessoComponent {

  readonly dialogRef = inject(MatDialogRef<DialogColaboradorInternoSemAcessoComponent>);

  onNoClick(): void {
    this.dialogRef.close();
  }

}
