import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "../util/base.component";
import { ToastrService } from "ngx-toastr";
import {
  combineLatest,
  filter,
  map,
  Subject,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from "rxjs";
import { environment } from "src/environments/environment";
import { Functions } from "../util/functions";
import { AppState } from "../interfaces/app-state.interface";
import { Store } from "@ngrx/store";
import { loadGovBrAccessToken, logout } from "../actions/auth.actions";
import { loadEmpresas, updateEmpresas } from "../actions/company.action";
import { GovBrToken } from "../model/govbrtoken";
import {
  selectGovBrToken,
  selectIsResponsavelLegal,
  selectIsAdministrador,
  selectIsGestorAnvisa,
  selectIsGestorAnvisaConsulta,
  selectIsGestorCadastros,
} from "../selectors/auth.selectors";
import {
  selectEmpresas,
  selectEmpresasLoaded,
} from "../selectors/company.selector";
import { MatDialog } from "@angular/material/dialog";
import { DialogLoadEmpresasComponent } from "./dialog-load-empresas/dialog-load-empresas.component";
import { Constants } from "../util/constants";
import { DialogColaboradorInternoSemAcessoComponent } from "./dialog-colaborador-interno-sem-acesso/dialog-colaborador-interno-sem-acesso.component";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  isPerfilGestorAnvisa = false;
  isPerfilGestorAnvisaConsulta = false;
  isAdministrador = false;
  isGovBr = false;
  isSsoIntranet = false;

  readonly dialog = inject(MatDialog);

  constructor(
    public toastr: ToastrService,
    public activatedRoute: ActivatedRoute,
    private store: Store<AppState>
  ) {
    super();
  }

  openDialogLoadEmpresas(): void {
    const dialogRef = this.dialog.open(DialogLoadEmpresasComponent, {
      width: "500px",
      height: "500px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.logout();
    });
  }

  openDialogColaboradorSemAcesso(): void {
    const dialogRef = this.dialog.open(
      DialogColaboradorInternoSemAcessoComponent,
      {
        width: "500px",
        height: "300px",
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      this.logout();
    });
  }

  logout() {
    this.store.dispatch(logout());
  }

  ngOnInit(): void {
    this.configuraPerfis();
  
    if (!environment.IS_LOCAL && this.isGovBr) {
      this.handleGovBrToken();
      return; // No need to proceed further if `handleGovBrToken` is executed
    }

    if (this.isSsoIntranet) {
      // Simplified handling of profile checks
      combineLatest([
        this.store.select(selectIsAdministrador),
        this.store.select(selectIsGestorAnvisa),
        this.store.select(selectIsGestorAnvisaConsulta),
      ])
        .pipe(
          map(([isAdministrador, isGestorAnvisa, isGestorAnvisaConsulta]) => {
            const hasNoProfiles =
              !isAdministrador && !isGestorAnvisa && !isGestorAnvisaConsulta;
            return hasNoProfiles;
          }),
          tap((hasNoProfiles) => {
            if (hasNoProfiles) {
              this.openDialogColaboradorSemAcesso();
            }
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  private configuraPerfis(): void {
    const usuarioLogado = Functions.getUsuarioLogadoFromSession();
    this.isPerfilGestorAnvisa =
      usuarioLogado.selectedProfile === Constants.PERFIL_GESTOR_ANVISA;
    this.isPerfilGestorAnvisaConsulta =
      usuarioLogado.selectedProfile === Constants.PERFIL_GESTOR_ANVISA_CONSULTA;
    this.isAdministrador =
      usuarioLogado.selectedProfile === Constants.PERFIL_ADMINISTRADOR;
    this.isGovBr = this.hasGovbrRole(usuarioLogado.roles);
    this.isSsoIntranet = this.hasSsoIntranetRole(usuarioLogado.roles);
  }

  private handleGovBrToken() {
    this.store.dispatch(loadGovBrAccessToken());

    // Wait for the GovBrToken to be loaded and then load empresas
    this.store
      .select(selectGovBrToken)
      .pipe(
        filter((token): token is GovBrToken => !!token),
        tap((token) =>
          this.store.dispatch(
            loadEmpresas({ govBrAccessToken: token.access_token })
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Handling the loaded state and then wait for empresas
    this.store
      .select(selectEmpresasLoaded)
      .pipe(
        filter((loaded) => loaded === true), // Ensure it only proceeds when loaded is true
        switchMap(() =>
          this.store.select(selectEmpresas).pipe(
            withLatestFrom(
              this.store.select(selectIsResponsavelLegal),
              this.store.select(selectIsGestorCadastros)
            ),
            map(
              ([
                empresas,
                isResponsavelLegal,
                isAdministrador
              ]) => {
                const cnpjs =
                  empresas.length > 0
                    ? empresas.map((empresa) => empresa.cnpj)
                    : [];
                const hasOtherProfiles =
                  isAdministrador;
                const hasNoProfiles =
                  !isResponsavelLegal &&
                  !isAdministrador;
                return {
                  cnpjs,
                  isResponsavelLegal,
                  hasOtherProfiles,
                  hasNoProfiles,
                };
              }
            ),
            tap(
              ({
                cnpjs,
                isResponsavelLegal,
                hasOtherProfiles,
                hasNoProfiles,
              }) => {
                this.store.dispatch(updateEmpresas({ cnpjs }));
                if (
                  (cnpjs.length === 0 &&
                    isResponsavelLegal &&
                    !hasOtherProfiles) ||
                  hasNoProfiles
                ) {
                  this.openDialogLoadEmpresas();
                }
              }
            )
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private hasGovbrRole(roles: string[]): boolean {
    return roles.includes("govbr");
  }

  private hasSsoIntranetRole(roles: string[]): boolean {
    return roles.includes("sso-intranet");
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
