import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { Observable, catchError, from, switchMap } from "rxjs";
import { DashboardOverviewDTO } from "../model/dashboard-overview";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  private API = `${environment.API}/api/v1/dashboard`;

  constructor(
    private httpClient: HttpClient,
    private readonly keycloakService: KeycloakService
  ) {}

  private getToken(): Observable<string> {
    return from(this.keycloakService.getToken());
  }

  getDashboardOverview(): Observable<DashboardOverviewDTO> {
    return this.getToken().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        });

        return this.httpClient.get<DashboardOverviewDTO>(`${this.API}/overview`, { headers });
      }),
      catchError((error) => {
        console.error("Error fetching dashboard data", error);
        throw error;
      })
    );
  }
}
