import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { AppState } from 'src/app/interfaces/app-state.interface';
import { selectColaboradorSerpro } from 'src/app/selectors/employee.selector';
import { ToastrFunctions } from 'src/app/util/toastr.functions';
import { insereColaboradorInterno, resetColaboradorSerpro, verificarValidadeCPF } from "src/app/actions/employee.action";
import { ColaboradorSerpro } from 'src/app/model/colaboradorserpro';
import { Functions } from 'src/app/util/functions';

@Component({
  selector: 'app-dialog-add-colab-inter',
  templateUrl: './dialog-add-colab-inter.component.html',
  styleUrls: ['./dialog-add-colab-inter.component.scss']
})
export class DialogAddColabInterComponent implements OnInit {

  colaboradorSerpro$: Observable<ColaboradorSerpro | undefined>;
  colaboradorSerproSubscription: Subscription | undefined;
  colaboradorSerpro: ColaboradorSerpro | undefined;
  nome = '';
  email = '';
  cpf = '';
  isSearchSuccessful = false;
  hasInputError = false;

  constructor(
    private store: Store<AppState>,
    public toastr: ToastrService
  ) {
    this.colaboradorSerpro$ = this.store.pipe(select(selectColaboradorSerpro));
  }

  ngOnInit() {
    this.colaboradorSerproSubscription = this.colaboradorSerpro$.subscribe(colaborador => {
      this.isSearchSuccessful = !!colaborador;
      if (colaborador) {
        this.colaboradorSerpro = { ...colaborador }; // Create a local copy of the object
        this.nome = colaborador.Nome;
        this.email = colaborador.email;
        this.cpf = colaborador.CPF;
      }
    });
  }

  checarValidadeCPF() {
    this.hasInputError = false;

    if (!this.cpf.trim()) {
      ToastrFunctions.showWarning(this.toastr, "Preencha o CPF.");
      this.hasInputError = true;
      return;
    }
  
    // Validate the CPF using the Functions.isCpf method
    const cpfValidationMessage = Functions.isCpf(this.cpf);
    if (cpfValidationMessage !== "CPF válido.") {
      ToastrFunctions.showWarning(this.toastr, cpfValidationMessage);
      this.hasInputError = true;
      return;
    }

    this.store.dispatch(verificarValidadeCPF({ cpf: this.cpf }));
  }

  insereColaboradorInterno(): void {
    if (!this.cpf.trim()) {
      ToastrFunctions.showWarning(this.toastr, "Por favor, preencha o CPF.");
      return;
    }

    if (this.colaboradorSerpro) {
      this.colaboradorSerpro.Nome = this.nome;
      this.colaboradorSerpro.CPF = this.cpf;
      
      this.store.dispatch(insereColaboradorInterno({ colaborador: this.colaboradorSerpro }));
    }
  }

  reset() {
    this.nome = "";
    this.email = "";
    this.cpf = "";
    this.isSearchSuccessful = false;
    this.colaboradorSerpro = undefined; // Reset the local variable
    this.store.dispatch(resetColaboradorSerpro());

    this.hasInputError = false;
  }

}
