import { PessoaJuridica } from "../model/pessoajuridica.model";
import { UsuarioLogado } from "../model/usuariologado.model";
import * as CryptoJS from 'crypto-js';
import { environment } from "src/environments/environment";

export class Functions {

  public static secretKey = environment.CRYPTO_SECRET_KEY;

  public static isCacheValid(cachedTimestamp: Date | null, maxCacheDuration = 300000): boolean {
    const now = new Date().getTime();
    const cacheTime = new Date(cachedTimestamp ? cachedTimestamp : 0).getTime();
    return (now - cacheTime) < maxCacheDuration;
  }

  public static isCpf(str: string): string {
    // Remove any non-numeric characters
    const cpf = str.replace(/\D/g, "");
  
    // Check if the CPF has the correct length
    if (cpf.length !== 11) {
      return "CPF deve ter 11 dígitos.";
    }
  
    // Check if all characters are the same (e.g., "00000000000")
    const areAllCharactersTheSame = new Set(cpf).size === 1;
    if (areAllCharactersTheSame) {
      return "CPF inválido: todos os dígitos são iguais.";
    }
  
    // Validate the CPF format using a regular expression
    const cpfPattern = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
    if (!cpfPattern.test(cpf)) {
      return "Formato de CPF inválido.";
    }
  
    // Convert the CPF string into an array of digits
    const digits = cpf.split("").map(Number);
  
    // Calculate the first validation digit
    let sum1 = 0;
    for (let i = 0; i < 9; i++) {
      sum1 += digits[i] * (10 - i);
    }
    const calculatedDigit1 = sum1 % 11 < 2 ? 0 : 11 - (sum1 % 11);
  
    // Calculate the second validation digit
    let sum2 = 0;
    for (let i = 0; i < 10; i++) {
      sum2 += digits[i] * (11 - i);
    }
    const calculatedDigit2 = sum2 % 11 < 2 ? 0 : 11 - (sum2 % 11);
  
    // Check the calculated validation digits with the actual digits
    if (digits[9] !== calculatedDigit1) {
      return "Primeiro dígito verificador inválido.";
    }
    if (digits[10] !== calculatedDigit2) {
      return "Segundo dígito verificador inválido.";
    }
  
    return "CPF válido.";
  }  

  public static isEmail(str: string) {
    const pattern = /\S+@\S+\.\S+/;
    return pattern.test(str);
  }

  public static isOnlyText(str: string) {
    return /^[a-zA-Z]+$/.test(str);
  }

  public static isValidName(str: string): boolean {
    return /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/.test(str);
  }  

  public static isNumber(str: string) {
    return !Number.isNaN(Number(str));
  }

  public static isCnpj(str: string): string {
    // Remove any non-numeric characters
    const cnpj = str.replace(/\D/g, "");
  
    // Check if the CNPJ has the correct length
    if (cnpj.length !== 14) {
      return "CNPJ deve ter 14 dígitos.";
    }
  
    // Check if all characters are the same (e.g., "00000000000000")
    const areAllCharactersTheSame = new Set(cnpj).size === 1;
    if (areAllCharactersTheSame) {
      return "CNPJ inválido: todos os dígitos são iguais.";
    }
  
    // Validate the CNPJ format using a regular expression
    const cnpjPattern = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
    if (!cnpjPattern.test(cnpj)) {
      return "Formato de CNPJ inválido.";
    }
  
    // Calculate the validation digits and compare with the given digits
    const digits = cnpj.split("").map(Number);
    const validation1 =
      digits[0] * 5 +
      digits[1] * 4 +
      digits[2] * 3 +
      digits[3] * 2 +
      digits[4] * 9 +
      digits[5] * 8 +
      digits[6] * 7 +
      digits[7] * 6 +
      digits[8] * 5 +
      digits[9] * 4 +
      digits[10] * 3 +
      digits[11] * 2;
    const validation2 =
      digits[0] * 6 +
      digits[1] * 5 +
      digits[2] * 4 +
      digits[3] * 3 +
      digits[4] * 2 +
      digits[5] * 9 +
      digits[6] * 8 +
      digits[7] * 7 +
      digits[8] * 6 +
      digits[9] * 5 +
      digits[10] * 4 +
      digits[11] * 3 +
      digits[12] * 2;
    const calculatedDigit1 = validation1 % 11 < 2 ? 0 : 11 - (validation1 % 11);
    const calculatedDigit2 = validation2 % 11 < 2 ? 0 : 11 - (validation2 % 11);
  
    if (digits[12] !== calculatedDigit1) {
      return "Primeiro dígito verificador inválido.";
    }
    if (digits[13] !== calculatedDigit2) {
      return "Segundo dígito verificador inválido.";
    }
  
    return "CNPJ válido.";
  }  

  public static removeMask(cnpj: string): string {
    // Remove all non-numeric characters
    return cnpj.replace(/\D/g, "");
  }

  public static applyMask(value: string, mask: string): string {
    let maskedValue = "";
    let index = 0;
    for (let i = 0; i < mask.length && index < value.length; i++) {
      if (mask[i] === "X") {
        maskedValue += value[index];
        index++;
      } else {
        maskedValue += mask[i];
      }
    }
    return maskedValue;
  }

  public static setCharAt(str: string, index: number, chr: any) {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
  }

  public static mascaraCnpj(cnpj: string | undefined) {
    if (cnpj === undefined || cnpj === "" || cnpj === null) {
      return "";
    }
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  }

  public static formatPessoaJuridicaDescription(pj: PessoaJuridica) {
    let result = null;
    const cnpjMask = Functions.mascaraCnpj(pj.numeroCNPJ);
    const cnpj = Functions.formatCnpj(cnpjMask);
    result = pj.razaoSocial + " - CNPJ - " + cnpj;
    result = result.replace("&", " e ");
    return result;
  }

  public static formatCnpj(cnpj: string | undefined) {
    const cnpjMask = Functions.mascaraCnpj(cnpj);
    let result = Functions.setCharAt(cnpjMask, 0, "X");
    result = Functions.setCharAt(result, 1, "X");
    result = Functions.setCharAt(result, 17, "X");
    return result;
  }

  public static maskCPF(cpf: string) {
    if (!cpf || cpf.length !== 11) {
        throw new Error("CPF deve conter 11 dígitos.");
    }
    
    // Formatar o CPF
    const formattedCPF = '***.***.' + cpf.slice(6, 9) + '-**';
    return formattedCPF;
  }

  public static removeCnpj(str: any) {
    return Functions.removeLastChars(str, 28);
  }

  public static removeLastChars(text: string, n: number) {
    if (text === undefined || text === null || text === "") {
      return "";
    }
    n *= -1;
    return text.slice(0, n);
  }

  public static decodeToken(token: string) {
    const _decodeToken = (token: string) => {
      try {
        return JSON.parse(atob(token));
      } catch {
        return;
      }
    };
    return token
      .split(".")
      .map((token) => _decodeToken(token))
      .reduce((acc, curr) => {
        if (curr) acc = { ...acc, ...curr };
        return acc;
      }, Object.create(null));
  }

  public static setUsuarioLogadoIntoSession(usuarioLogado: UsuarioLogado) {
    try {
      const encryptedValue = CryptoJS.AES.encrypt(
        JSON.stringify(usuarioLogado),
        this.secretKey
      ).toString();
      sessionStorage.setItem("usuarioLogado", encryptedValue);           
    } catch (e) {
      console.error('Error setting item in session storage', e);
    }
  }

  public static getUsuarioLogadoFromSession(): UsuarioLogado {
    try {
      const encryptedValue = sessionStorage.getItem("usuarioLogado");
      if (encryptedValue) {
        const bytes = CryptoJS.AES.decrypt(encryptedValue, this.secretKey);
        const decryptedValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedValue as UsuarioLogado; // Explicitly cast to UsuarioLogado
      }
    } catch (e) {
      console.error('Error getting item from session storage', e);
    }
    return new UsuarioLogado("", "", "", "", [], "",[],"", []);
  }

  public static isEmpty(obj: any) {
    if (obj === undefined || obj === null || obj === "") {
      return true;
    }
    return false;
  }

  public static normalizeString(input: string): string {
    return input
      .toLowerCase() // Convert the string to lowercase
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  }
}
