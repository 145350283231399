import { createSelector, createFeatureSelector } from '@ngrx/store';
import { createIsLoadedSelector } from './genericselector.interface';
import { DashboardState } from '../interfaces/dashboard-state.interface';

export const selectDashboardFeature = createFeatureSelector<DashboardState>('dashboard');

export const selectDashboard = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.dashboard
);

export const selectDashboardError = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.error
);

export const getDashboardIsLoaded =  createIsLoadedSelector(selectDashboardFeature);
