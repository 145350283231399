import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Store, select } from '@ngrx/store';
import { ToastrFunctions } from "src/app/util/toastr.functions";
import { Observable, Subscription } from 'rxjs';
import { AppState } from "src/app/interfaces/app-state.interface";
import { selectColaboradorSerpro } from "src/app/selectors/employee.selector";
import { insereColaborador, resetColaboradorSerpro, verificarValidadeCPF } from "src/app/actions/employee.action";
import { ColaboradorSerpro } from "src/app/model/colaboradorserpro";
import { Functions } from "src/app/util/functions";

@Component({
  selector: "app-dialog-add-colaborador",
  templateUrl: "./dialog-add-colaborador.component.html",
})
export class DialogAddColaboradorComponent implements OnInit, OnDestroy {
  colaboradorSerpro$: Observable<ColaboradorSerpro | undefined>;
  colaboradorSerproSubscription: Subscription | undefined;
  colaboradorSerpro: ColaboradorSerpro | undefined;
  nome = '';
  email = '';
  cpf = '';
  pessoaJuridicaId = '';

  isSearchSuccessful = false;
  hasInputError = false;

  constructor(
    private store: Store<AppState>,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.colaboradorSerpro$ = this.store.pipe(select(selectColaboradorSerpro));
  }

  ngOnInit() {
    this.pessoaJuridicaId = this.data.pessoaJuridicaId;

    this.colaboradorSerproSubscription = this.colaboradorSerpro$.subscribe(colaborador => {
      this.isSearchSuccessful = !!colaborador;
      if (colaborador) {
        this.colaboradorSerpro = { ...colaborador }; // Create a local copy of the object
        this.nome = colaborador.Nome;
        this.email = colaborador.email;
        this.cpf = colaborador.CPF;
      }
    });
  }

  verificarValidadeCPF(): void {
    this.hasInputError = false;  // Reset error state

    // Trim and validate if the CPF is empty
    if (!this.cpf.trim()) {
      ToastrFunctions.showWarning(this.toastr, "Preencha o CPF.");
      this.hasInputError = true;  // Set error flag
      return;
    }
  
    // Validate the CPF using the Functions.isCpf method
    const cpfValidationMessage = Functions.isCpf(this.cpf);
    if (cpfValidationMessage !== "CPF válido.") {
      ToastrFunctions.showWarning(this.toastr, cpfValidationMessage);
      this.hasInputError = true;  // Set error flag
      return;
    }
  
    // Dispatch the action if the CPF is valid
    this.store.dispatch(verificarValidadeCPF({ cpf: this.cpf }));
  }
  

  insereColaborador(): void {
    if (!this.cpf.trim()) {
      ToastrFunctions.showWarning(this.toastr, "Preencha o CPF.");
      return;
    }

    if (this.colaboradorSerpro) {
      this.colaboradorSerpro.Nome = this.nome;
      this.colaboradorSerpro.CPF = this.cpf;

      this.store.dispatch(insereColaborador({pessoaJuridicaId: this.pessoaJuridicaId, colaborador: this.colaboradorSerpro }));

      this.reset();
    }
  }

  reset() {
    this.nome = "";
    this.email = "";
    this.cpf = "";
    this.isSearchSuccessful = false;
    this.colaboradorSerpro = undefined; // Reset the local variable
    this.store.dispatch(resetColaboradorSerpro());

    this.hasInputError = false;  // Reset error state
  }

  ngOnDestroy() {
    if (this.colaboradorSerproSubscription) {
      this.colaboradorSerproSubscription.unsubscribe();
    }
  }
}
