import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-load-empresas',
  templateUrl: './dialog-load-empresas.component.html',
  styleUrls: ['./dialog-load-empresas.component.scss']
})
export class DialogLoadEmpresasComponent {

  readonly dialogRef = inject(MatDialogRef<DialogLoadEmpresasComponent>);
  //readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  //readonly animal = model(this.data.animal);

  onNoClick(): void {
    this.dialogRef.close();
  }

}


