import { createAction, props } from "@ngrx/store";
import { DashboardOverviewDTO } from "../model/dashboard-overview";

export const loadDashboard = createAction("[Dashboard] Load Dashboard");
export const loadDashboardSuccess = createAction(
  "[Dashboard] Load Dashboard Success",
  props<{ dashboard: DashboardOverviewDTO }>()
);
export const loadDashboardFailure = createAction(
  "[Dashboard] Load Dashboard Failure",
  props<{ error: any }>()
);
export const clearDashboardState = createAction("[Dashboard] Clear State");
