import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SistemaService } from "../service/sistema.service";
import { IntegracaoService } from "../service/integracao.service";
import { KeycloakClient } from "../model/keycloakclient.model";
import { BaseComponent } from "../util/base.component";
import { Sistema } from "../model/sistema.model";
import { Integracao } from "../model/integracao.model";
import { Constants } from "../util/constants";
import { UsuarioLogado } from "../model/usuariologado.model";
import { EMPTY, switchMap, take } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { AddIntegrationDialogComponent } from "./add-integration-dialog/add-integration-dialog.component";
import { ViewIntegrationDialogComponent } from "./view-integration-dialog/view-integration-dialog.component";
import { KeycloakClientIntegration } from "../model/keycloakclienterepresentation.model";
import { DialogComponent } from "../components/dialog/dialog.component";
import { HttpErrorResponse } from "@angular/common/http";
import { SessionStorageService } from "../service/sessionstorage.service";

@Component({
  selector: "app-integrador",
  templateUrl: "./integrador.component.html",
  styleUrls: ["./integrador.component.scss"],
})
export class IntegradorComponent extends BaseComponent implements OnInit {
  isPerfilAdministrador = false;
  sistemas: Sistema[] = [];
  integracao: Integracao | null = null;
  usuarioLogado!: UsuarioLogado | null;
  selectedSistema: Sistema | null = null; // Add this property to track the selected Sistema
  displayedColumns: string[] = ["name", "actions"]; // Add columns as needed

  showSpinner = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    public sistemaService: SistemaService,
    public integracaoService: IntegracaoService,
    private sessionStorageService: SessionStorageService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.showSpinner = true;
    this.sessionStorageService
      .getUsuarioLogadoFromSession()
      .pipe(
        take(1),
        switchMap((usuarioLogado) => {
          this.usuarioLogado = usuarioLogado;
          if (this.usuarioLogado) {
            return this.activatedRoute.data;
          } else {
            this.router.navigate(["/home"]);
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: (data) => {
          console.log('Received data:', data); // Log the emitted data
          const perfis = data["perfis"];
          this.isPerfilAdministrador = super.checkPerfilAdministrador(perfis);
          if (this.isPerfilAdministrador) {
            this.loadSistemas();
          } else {
            this.router.navigate(["/home"]);
          }
        },
        error: () => {
          this.toastr.error("Erro ao carregar informações do usuário");
          this.showSpinner = false;
        },
        complete: () => {
          this.showSpinner = false;
        },
      });
  }
  

  onSistemaSelected(sistema: Sistema): void {
    this.selectedSistema = sistema;
    this.loadIntegrationForSelectedSistema();
  }

  openIntegrationDialog(integration?: KeycloakClient): void {
    const isEditMode = !!integration; // Determine if it's edit mode based on if integration data is provided
    const dialogData: KeycloakClient =
      { ...integration, sistemaId: this.selectedSistema?.id } as KeycloakClient ||
      ({ sistemaId: this.selectedSistema?.id } as KeycloakClient);

    const dialogRef = this.dialog.open(AddIntegrationDialogComponent, {
      width: "400px",
      data: {
        ...dialogData,
      },
    });

    dialogRef.afterClosed().subscribe((result: KeycloakClient) => {
      if (result) {
        if (isEditMode && this.integracao?.id !== undefined) {
          this.updateIntegration(this.integracao.id, result); // Ensure id is defined
        } else {
          this.addIntegration(result);
        }
      }
    });
  }

  openDialogExclusao(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    integracaoId: number
  ): void {
    const dialogDelete = this.dialog.open(DialogComponent, {
      width: "400px",
      enterAnimationDuration,
      exitAnimationDuration,
    });
    dialogDelete.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteIntegration(integracaoId);
      }
    });
  }

  viewIntegration(clientId: string): void {
    this.showSpinner = true;
    this.integracaoService
      .loadIntegrationCompleteInformations(clientId)
      .subscribe({
        next: (details) => {
          this.viewDetails(details);
        },
        error: () => {
          this.toastr.error(
            "Erro ao carregar informações completas da integração"
          );
          this.showSpinner = false;
        },
        complete: () => {
          this.showSpinner = false;
        },
      });
  }

  viewDetails(integration: KeycloakClientIntegration): void {
    this.dialog.open(ViewIntegrationDialogComponent, {
      width: "500px",
      data: integration,
    });
  }

  editIntegration(clientId: string): void {
    this.showSpinner = true;
    this.integracaoService
      .loadIntegrationPartialInformations(clientId)
      .subscribe({
        next: (integration) => {
          this.openIntegrationDialog(integration);
        },
        error: () => {
          this.toastr.error("Erro ao carregar dados da integração");
          this.showSpinner = false;
        },
        complete: () => {
          this.showSpinner = false;
        },
      });
  }

  deleteIntegration(integrationId: number): void {
    this.showSpinner = true;
    this.integracaoService.deleteIntegration(integrationId).subscribe({
      next: () => {
        this.toastr.success("Integração excluída com sucesso");
        this.integracao = null;
      },
      error: () => {
        this.toastr.error("Erro ao excluir integração");
        this.showSpinner = false;
      },
      complete: () => {
        this.showSpinner = false;
      },
    });
  }

  loadSistemas(): void {
    this.showSpinner = true;
    const perfilNo = this.getPerfilNo();

    this.sistemaService
      .findAllSistemasByParams(perfilNo)
      .subscribe({
        next: (sistemas) => {
          this.sistemas = sistemas;
        },
        error: () => {
          this.toastr.error("Erro ao carregar sistemas");
          this.showSpinner = false;
        },
        complete: () => {
          this.showSpinner = false;
        },
      });
  }

  loadIntegrationForSelectedSistema(): void {
    if (this.selectedSistema) {
      this.showSpinner = true;
      this.integracaoService
        .loadIntegration(this.selectedSistema.id)
        .subscribe({
          next: (integracao) => {
            this.integracao = integracao;
          },
          error: (error: HttpErrorResponse) => {
            console.log(error);
            if (error.status === 404) {
              // Handle 404 error here
              console.log("Integração não encontrada para o sistema selecionado.");
              this.toastr.info("Integração não encontrada para o sistema selecionado, crie uma nova");
            } else {
              // Handle other errors here
              console.error(error);
              this.toastr.error("Erro ao carregar integração para o sistema selecionado");
            }
    
            this.showSpinner = false;
          },
          complete: () => {
            this.showSpinner = false;
          },
        });
    } else {
      this.integracao = null;
    }
  }

  addIntegration(keycloakClientDTO: KeycloakClient): void {
    this.showSpinner = true;
    this.integracaoService.createIntegration(keycloakClientDTO).subscribe({
      next: () => {
        this.toastr.success("Integração adicionada com sucesso");
        this.loadIntegrationForSelectedSistema();
      },
      error: () => {
        this.toastr.error("Erro ao adicionar integração");
        this.showSpinner = false;
      },
      complete: () => {
        this.showSpinner = false;
      },
    });
  }

  updateIntegration(
    integrationId: number,
    updatedClientDTO: KeycloakClient
  ): void {
    this.showSpinner = true;
    this.integracaoService
      .updateIntegration(integrationId, updatedClientDTO)
      .subscribe({
        next: () => {
          this.toastr.success("Integração atualizada com sucesso");
          this.loadIntegrationForSelectedSistema();
        },
        error: () => {
          this.toastr.error("Erro ao atualizar integração");
          this.showSpinner = false;
        },
        complete: () => {
          this.showSpinner = false;
        },
      });
  }

  trackBySistema(index: number, sistema: Sistema): string {
    return sistema.id;
  }

  getPerfilNo(): string {
    return Constants.PERFIL_ADMINISTRADOR;
  }
}
