/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { Observable, map } from "rxjs";
import { Perfil } from "../model/perfil.model";
import { PerfisSistemaCustom } from "../model/perfissistemacustom.model";

@Injectable({
  providedIn: "root",
})
export class PerfilService {
  public readonly API = `${environment.API}/api/v1/perfis`;

  private token: unknown;

  constructor(
    private httpClient: HttpClient,
    private readonly keycloackService: KeycloakService
  ) {}

  getToken() {
    return this.keycloackService.getToken();
  }

  getPerfilAuthorized(): Observable<Perfil[]> {
    this.token = this.getToken();
    const api_key = this.token;

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${api_key}`,
    });

    return this.httpClient.get<Perfil[]>(`${this.API}/autoriza-perfil-pessoa-juridica-by-cpf`, {
      headers: headers
    });
  }

  findPerfilBySistema(coSistema: string): Observable<Perfil[]> {
    
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.httpClient.get<Perfil[]>(`${this.API}/find-perfil-by-sistema/${coSistema}`, { headers });
  }

  findSistemaPerfilByUsuarioRepresentante(idUsuarioRepresentante: string, coSistema?: string, idsPerfis?: string[], page = 0, size = 10): Observable<PerfisSistemaCustom> {
    const token = this.getToken(); // Ensure you have a method getToken() that retrieves the token
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    // Building the query string
    let queryParams = `idUsuarioRepresentante=${encodeURIComponent(idUsuarioRepresentante)}&page=${page}&size=${size}`;

    if (coSistema) {
      queryParams += `&coSistema=${encodeURIComponent(coSistema)}`;
    }

    if (idsPerfis && idsPerfis.length > 0) {
      queryParams += `&idsPerfis=${encodeURIComponent(idsPerfis.join(','))}`;
    }

    return this.httpClient.get<PerfisSistemaCustom>(`${this.API}/find-sistema-perfil-by-usuario-representante?${queryParams}`, { headers })
      .pipe(map(res => res));
  }

  findPerfilByCoSistemaAndIdUsuarioRepresentante(coSistema: string, idUsuarioRepresentante: string): Observable<Perfil[]> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.httpClient.get<Perfil[]>(`${this.API}/find-perfil-by-sistema-and-id-usuario-representante/${coSistema}/${idUsuarioRepresentante}`, { headers });
  }

}
