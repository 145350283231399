import {
  Component,
  ViewChild,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AppState } from "../interfaces/app-state.interface";
import { Store, select } from "@ngrx/store";
import { DialogAddColabInterComponent } from "./dialog-add-colab-inter/dialog-add-colab-inter.component";
import { DialogAddPerfilColabComponent } from "./dialog-add-perfil-colab/dialog-add-perfil-colab.component";
import { PerfisInterno } from "../model/perfisinterno";
import { combineLatest, Observable, Subject, Subscription } from "rxjs";
import {
  selectAllPerfisInterno,
  selectIsUpdatedPerfilInterno,
} from "../selectors/perfilinterno.selector";
import { PerfilInternoCustom } from "../model/perfilinternocustom";
import { Functions } from "../util/functions";
import { ToastrFunctions } from "../util/toastr.functions";
import {
  choosePerfisInterno,
  setUpdatedPerfilInterno,
} from "../actions/perfilinterno.action";
import { takeUntil } from "rxjs/operators";
import { FormGroup } from "@angular/forms";
import { BaseComponent } from "../util/base.component";
import { ResetService } from "../service/reset.service";
import { selectProfile } from "../selectors/auth.selectors";
import { Constants } from "../util/constants";
import { selectIsUpdatedEmployee } from "../selectors/employee.selector";
import { setUpdatedEmployee } from "../actions/employee.action";
import { DashboardDialogComponent } from "../dashboard-dialog/dashboard-dialog.component";

@Component({
  selector: "app-gestao-perfil-colab-inter",
  templateUrl: "./gestao-perfil-colab-inter.component.html",
  styleUrls: ["./gestao-perfil-colab-inter.component.scss"],
})
export class GestaoPerfilColabInterComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  displayedColumns: string[] = ["nome", "ativo", "email", "perfis", "editar"];
  dataSource = new MatTableDataSource<PerfilInternoCustom>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageSizes: number[] = [5, 10, 20];
  totalElements = 0;

  searchForm!: FormGroup;
  placeholderText = "Digite o CPF";
  selectedCpfOrNomeOrEmail = "pesquisarCpf";
  searchValue = "";
  isCpf = false;
  isNome = false;
  isEmail = false;

  perfisInterno$!: Observable<PerfisInterno | null>;
  private readonly destroy$ = new Subject<void>();
  isPerfilGestorAnvisa = false;
  isAdministrador = false;
  private resetSubscription!: Subscription;
  isPerfilGestorAnvisaConsulta = false;
  hasInputError = false;

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private dialog: MatDialog,
    private resetService: ResetService
  ) {
    super();
    this.perfisInterno$ = this.store.pipe(select(selectAllPerfisInterno));

    this.store
      .select(selectProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile) => {
        this.isPerfilGestorAnvisaConsulta =
          profile === Constants.PERFIL_GESTOR_ANVISA_CONSULTA;
        this.isPerfilGestorAnvisa = profile === Constants.PERFIL_GESTOR_ANVISA;
        this.isAdministrador = profile === Constants.PERFIL_ADMINISTRADOR;

        if (
          !this.isPerfilGestorAnvisa &&
          !this.isPerfilGestorAnvisaConsulta &&
          !this.isAdministrador
        ) {
          this.router.navigate(["/colab-vinc-cnpj-cnes"]);
        } else {
          this.reset();
        }
      });

    combineLatest([
      this.store.select(selectIsUpdatedPerfilInterno),
      this.store.select(selectIsUpdatedEmployee),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([isUpdatedPerfilInterno, isUpdatedEmployee]: [boolean, boolean]) => {
          if (isUpdatedPerfilInterno || isUpdatedEmployee) {
            this.doSearch(0, 10);

            if (isUpdatedPerfilInterno) {
              this.store.dispatch(setUpdatedPerfilInterno({ updated: false }));
            }

            if (isUpdatedEmployee) {
              this.store.dispatch(setUpdatedEmployee({ updated: false }));
            }
          }
        }
      );
  }

  ngOnInit(): void {
    this.perfisInterno$
  .pipe(takeUntil(this.destroy$))
  .subscribe((perfisInterno) => {
    if (perfisInterno) {
      const shouldResetPaginator = this.totalElements !== perfisInterno.totalElements;
      
      this.dataSource = new MatTableDataSource<PerfilInternoCustom>(
        perfisInterno.content
      );
      this.totalElements = perfisInterno.totalElements;

      // Update the paginator's page size
      this.paginator.pageSize = perfisInterno.size;

      if (shouldResetPaginator) {
        // Reset paginator to the first page if total elements have changed
        this.paginator.firstPage();
      }
    } else {
      this.dataSource.data = []; // Ensure table is cleared if data is null
    }
  });


    this.resetSubscription = this.resetService.reset$.subscribe(() => {
      this.router.navigate(["/home"]);
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  changePlaceholder(value: string): void {
    this.searchValue = "";
    this.selectedCpfOrNomeOrEmail = value;
    this.placeholderText = this.getPlaceholderText(value);
  }

  private getPlaceholderText(value: string): string {
    switch (value) {
      case "pesquisarCpf":
        return "Digite o CPF";
      case "pesquisarNome":
        return "Digite o Nome";
      case "pesquisarEmail":
        return "Digite o E-mail";
      default:
        return "Digite o CPF";
    }
  }

  onInputChange(): void {
    if (
      this.searchValue.length === 11 &&
      Functions.isNumber(this.searchValue)
    ) {
      this.searchValue = Functions.applyMask(
        this.searchValue,
        "XXX.XXX.XXX-XX"
      );
    }
  }

  isValidSearch(): boolean {
    this.hasInputError = false; // Reset input error state
  
    // If all fields are empty, allow the search (find all)
    if (
      Functions.isEmpty(this.searchValue) &&
      !this.isCpf &&
      !this.isNome &&
      !this.isEmail
    ) {
      return true; // Allow search with no specific criteria
    }
  
    let validationMessage: string | null = null;

    // Validate based on the selected search type (CPF, Nome, Email)
    switch (this.selectedCpfOrNomeOrEmail) {
      case "pesquisarCpf":
        validationMessage = Functions.isCpf(this.searchValue);
        if (validationMessage !== "CPF válido.") {
          validationMessage = "Por favor, preencha um CPF válido.";
        } else {
          validationMessage = null;
        }
        break;
      case "pesquisarNome":
        if (!Functions.isValidName(this.searchValue) || this.searchValue.trim().length < 3) {
          validationMessage = "Por favor, insira um Nome válido com pelo menos 3 caracteres.";
        }
        break;
      case "pesquisarEmail":
        if (!Functions.isEmail(this.searchValue)) {
          validationMessage = "Por favor, preencha um E-mail válido.";
        }
        break;
      default:
        validationMessage = null;
    }
  
    // If validation failed, show a warning and return false
    if (validationMessage) {
      ToastrFunctions.showWarning(this.toastr, validationMessage);
      this.hasInputError = true;
      return false;
    }
  
    // Update the state flags for the selected type
    this.isCpf = this.selectedCpfOrNomeOrEmail === "pesquisarCpf";
    this.isNome = this.selectedCpfOrNomeOrEmail === "pesquisarNome";
    this.isEmail = this.selectedCpfOrNomeOrEmail === "pesquisarEmail";
  
    return true; // Validation passed
  }

  doSearch(pageIndex = 0, pageSize = 10): void {
    if (this.isValidSearch()) {
      this.store.dispatch(
        choosePerfisInterno({
          nuCpf: this.isCpf ? Functions.removeMask(this.searchValue) : "",
          noPessoaFisica: this.isNome ? this.searchValue : "",
          dsEmail: this.isEmail ? this.searchValue : "",
          page: pageIndex,
          size: pageSize,
        })
      );
    }
  }

  reset(): void {
    this.searchValue = "";
    this.isCpf = false;
    this.isNome = false;
    this.isEmail = false;
    this.doSearch(0, 10);
  }

  openDialogAddColabInter(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    const dialogRef = this.dialog.open(DialogAddColabInterComponent, {
      width: "600px",
      enterAnimationDuration,
      exitAnimationDuration,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Handle result if necessary
      }
    });
  }

  openDialogAddPerfilColab(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    nuCpf: string,
    idUsuarioRepresentante: string
  ): void {
    const dialogRef = this.dialog.open(DialogAddPerfilColabComponent, {
      width: "500px",
      enterAnimationDuration,
      exitAnimationDuration,
      data: { nuCpf, idUsuarioRepresentante },
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  openDashboardDialog(): void {
    this.dialog.open(DashboardDialogComponent, {
      width: '500px', // Adjust the width as needed
    });
  }

  nextPage(event: PageEvent): void {
    this.doSearch(event.pageIndex, event.pageSize);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.resetSubscription.unsubscribe();
  }
}
