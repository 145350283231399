// src/app/resolvers/perfil.resolver.ts
/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from "@angular/router";
import { PerfilService } from "../service/perfil.service";
import { catchError, of, tap } from "rxjs";
import { Store } from "@ngrx/store";
import { loadPerfisFailure, loadPerfisSuccess } from "../actions/auth.actions";
import { Functions } from "../util/functions";
import { PerfilInternoService } from "../service/perfilinterno.service";
import { GenericPerfil } from "../model/generic/perfil.base";

export const perfilResolver: ResolveFn<GenericPerfil[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const store = inject(Store);
  const perfilService = inject(PerfilService);
  const perfilInternoService = inject(PerfilInternoService);
  const usuarioLogado = Functions.getUsuarioLogadoFromSession();

  if (usuarioLogado.roles.includes('sso-intranet')) {
    return perfilInternoService.getPerfilAuthorized().pipe(
      tap((perfis) => {
        store.dispatch(loadPerfisSuccess({ perfis }));
      }),
      catchError((error) => {
        store.dispatch(loadPerfisFailure({ error }));
        return of([]);
      })
    );
  } else if (usuarioLogado.roles.includes('govbr')) {
    return perfilService.getPerfilAuthorized().pipe(
      tap((perfis) => {
        store.dispatch(loadPerfisSuccess({ perfis }));
      }),
      catchError((error) => {
        store.dispatch(loadPerfisFailure({ error }));
        return of([]);
      })
    );
  }

  // Add logic for other roles if necessary
  return of([]);
};
