// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dialog Title */
.dialog-title {
  font-weight: bold;
  text-align: center;
}

/* Dialog Content */
.dialog-content {
  padding: 20px 10px;
  font-size: 16px;
  line-height: 1.6;
}

/* Dashboard Data Container */
.dashboard-data {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Row Styling */
.dashboard-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Bold Label Text */
.bold-label {
  font-weight: bold;
}

/* No Data Fallback */
.no-data {
  text-align: center;
  padding: 20px;
}

/* Dialog Actions */
.dialog-actions {
  justify-content: center;
  padding: 10px;
}

/* Close Button */
.close-button {
  font-weight: bold;
  background-color: #0d47a1;
  padding: 8px 24px;
  border-radius: 15px;
}

.mat-mdc-button.close-button:not(:disabled) {
  color: white !important; /* Ensure your color takes precedence */
  background-color: #0d47a1 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard-dialog/dashboard-dialog.component.scss"],"names":[],"mappings":"AAAA,iBAAA;AACA;EACI,iBAAA;EACA,kBAAA;AACJ;;AAEE,mBAAA;AACA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEE,6BAAA;AACA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEE,gBAAA;AACA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;;AAEE,oBAAA;AACA;EACE,iBAAA;AACJ;;AAEE,qBAAA;AACA;EACE,kBAAA;EACA,aAAA;AACJ;;AAEE,mBAAA;AACA;EACE,uBAAA;EACA,aAAA;AACJ;;AAEE,iBAAA;AACA;EACE,iBAAA;EACA,yBAAA;EACA,iBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,uBAAA,EAAA,uCAAA;EACA,oCAAA;AACJ","sourcesContent":["/* Dialog Title */\n.dialog-title {\n    font-weight: bold;\n    text-align: center;\n  }\n  \n  /* Dialog Content */\n  .dialog-content {\n    padding: 20px 10px;\n    font-size: 16px;\n    line-height: 1.6;\n  }\n  \n  /* Dashboard Data Container */\n  .dashboard-data {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n  }\n  \n  /* Row Styling */\n  .dashboard-row {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  /* Bold Label Text */\n  .bold-label {\n    font-weight: bold;\n  }\n  \n  /* No Data Fallback */\n  .no-data {\n    text-align: center;\n    padding: 20px;\n  }\n  \n  /* Dialog Actions */\n  .dialog-actions {\n    justify-content: center;\n    padding: 10px;\n  }\n  \n  /* Close Button */\n  .close-button {\n    font-weight: bold;\n    background-color: #0d47a1;\n    padding: 8px 24px;\n    border-radius: 15px;\n  }\n\n  .mat-mdc-button.close-button:not(:disabled) {\n    color: white !important; /* Ensure your color takes precedence */\n    background-color: #0d47a1 !important;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
