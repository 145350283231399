import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { getCompanyIsLoaded } from '../selectors/company.selector';
import { getEmployeeIsLoaded } from '../selectors/employee.selector';
import { getLogsIsLoaded } from '../selectors/log.selector';
import { getPerfilIsLoaded } from '../selectors/perfil.selector';
import { getSistemaIsLoaded } from '../selectors/sistema.selector';
import { AppState } from '../interfaces/app-state.interface';
import { getPerfisInternoIsLoaded } from '../selectors/perfilinterno.selector';
import { getDashboardIsLoaded } from '../selectors/dashboard.selector';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  
  isLoaded = true;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const loadingSubscription = combineLatest([
      this.store.select(getCompanyIsLoaded),
      this.store.select(getEmployeeIsLoaded),
      this.store.select(getPerfilIsLoaded),
      this.store.select(getSistemaIsLoaded),
      this.store.select(getLogsIsLoaded),
      this.store.select(getPerfisInternoIsLoaded),
      this.store.select(getDashboardIsLoaded)
    ]).pipe(
      map(([companyLoaded, employeeLoaded, perfilLoaded, sistemaLoaded, logsLoaded, perfisInternoLoaded, dashboardIsLoaded]) =>
        [companyLoaded, employeeLoaded, perfilLoaded, sistemaLoaded, logsLoaded, perfisInternoLoaded, dashboardIsLoaded].every(status => Object.values(status).every(v => v))
      )
    ).subscribe(allLoaded => {
      if (this.isLoaded !== allLoaded) {
        this.isLoaded = allLoaded;
        this.changeDetector.detectChanges(); // Force immediate change detection
        console.log(allLoaded ? 'All parts of the application have finished loading.' : 'Waiting for all parts to finish loading...');
      }
    }, error => {
      console.error('Failed to load application parts due to', error);
      this.isLoaded = true; // Reset loading state in case of error
    });
  
    this.subscriptions.add(loadingSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
