// src/app/store/reducers/auth.reducer.ts
import { createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';
import { AuthState } from '../interfaces/auth-state.interface';
import { Constants } from '../util/constants';
import { Functions } from '../util/functions';

export const initialState: AuthState = {
  perfis: [],
  perfisInternos: [],
  perfisByPessoaJuridicaId: [],
  selectedProfile: null,
  error: null,
  token: null,
  hasWritePermission: false,
  isLoaded: {},
  isGestorAnvisa: false,
  isGestorAnvisaConsulta: false,
  isGestorCadastros: false,
  isResponsavelLegal: false,
  isAdministrador: false
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.loadPerfisSuccess, (state, { perfis }) => {
    const isGestorCadastros = perfis.some(perfil => perfil.noPerfil === Constants.PERFIL_GESTOR_CADASTROS);
    const isResponsavelLegal = perfis.some(perfil => perfil.noPerfil === Constants.PERFIL_RESPONSAVEL_LEGAL);
    const isGestorAnvisa = perfis.some(perfil => perfil.noPerfil === Constants.PERFIL_GESTOR_ANVISA);
    const isGestorAnvisaConsulta = perfis.some(perfil => perfil.noPerfil === Constants.PERFIL_GESTOR_ANVISA_CONSULTA);
    const isAdministrador = perfis.some(perfil => perfil.noPerfil === Constants.PERFIL_ADMINISTRADOR);

    return {
      ...state,
      perfis,
      isGestorCadastros,
      isResponsavelLegal,
      isGestorAnvisa,
      isGestorAnvisaConsulta,
      isAdministrador
    };
  }),
  on(AuthActions.loadPerfisFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(AuthActions.checkWritePermissionSuccess, (state, { hasPermission }) => ({
    ...state,
    hasWritePermission: hasPermission,
  })),
  on(AuthActions.checkWritePermissionFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(AuthActions.logout, () => initialState),

  on(AuthActions.loadGovBrAccessTokenSuccess, (state, { token }) => ({
    ...state,
    token,
    error: null
  })),
  on(AuthActions.loadGovBrAccessTokenFailure, (state, { error }) => ({
    ...state,
    error
  })),

  on(AuthActions.setSelectedProfile, (state, { profile }) => {
    // Update the profile in session storage
    const usuarioLogado = Functions.getUsuarioLogadoFromSession();
    usuarioLogado.selectedProfile = profile;
    Functions.setUsuarioLogadoIntoSession(usuarioLogado);
  
    return {
      ...state,
      selectedProfile: profile,
      error: null,
    };
  }),
  

  on(AuthActions.setWritePermission, (state, { hasPermission }) => ({
    ...state,
    hasWritePermission: hasPermission,
    error: null
  })),
);
