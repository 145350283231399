/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { PerfisInterno } from "../model/perfisinterno";
import { PerfilInterno } from "../model/perfilinterno";

@Injectable({
  providedIn: "root",
})
export class PerfilInternoService {
  private readonly API = `${environment.API}/api/v1/perfisinterno`;

  private token: unknown;
  perfisInterno$: any;
  selectedUser$: any;

  constructor(
    private httpClient: HttpClient,
    private readonly keycloackService: KeycloakService
  ) {}

  getToken() {
    return this.keycloackService.getToken();
  }

  findPerfilInternoByFilter(nuCpf = '', noPessoaFisica = '', dsEmail = '', page = 0, size = 20): Observable<PerfisInterno> {
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    
    const params = new HttpParams()
      .set('nuCpf', nuCpf)
      .set('noUsuario', noPessoaFisica)
      .set('dsEmail', dsEmail)
      .set('page', page.toString())
      .set('size', size.toString());

    return this.httpClient.get<PerfisInterno>(`${this.API}/find-perfil-interno-by-filter`, { headers, params });
  }

  findAllPerfilInterno(): Observable<PerfilInterno[]> {
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });    
    return this.httpClient.get<PerfilInterno[]>(`${this.API}/find-all-perfil-interno`, { headers });
  }

  
  getPerfilAuthorized(): Observable<PerfilInterno[]> {
    this.token = this.getToken();
    const api_key = this.token;

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${api_key}`,
    });

    return this.httpClient.get<PerfilInterno[]>(`${this.API}/autoriza-perfil-interno-colaborador-interno-by-id`, {
      headers: headers
    });
  }
  
}
